import * as s from '../selectors'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  count: number
}

const config: Config<Input, Output, State, never> = {
  moduleKey: 'wishlist',
  name: 'wishlist/useWishlistCount',
  createCacheKey: () => '',
  mapState: (state) => ({
    count: s.getItemsCount(state),
  }),
  areStatesEqual: (a, b) => a.items.data.length === b.items.data.length,
}

export default function useWishlistCount(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
