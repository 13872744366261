import * as t from './types'
import { Action } from './actions'
import * as at from './const'

export type State = {
  setupFinished: boolean
  data: t.User
}

export const defaultState: State = {
  setupFinished: false,
  data: {
    isLoggedIn: false,
    firstname: '',
    lastname: '',
    email: '',
    hasNewsletterRequested: false,
    isSocialSignin: false,
    // wishlistId: ''
  },
}

export default function reducer(state = defaultState, action: Action): State {
  switch (action.type) {
    case at.FINISH_SETUP:
      return { ...state, setupFinished: true }
    case at.LOGOUT:
      return { ...state, data: defaultState.data }
    case at.SET_USER: {
      if (!action.payload) return state
      return {
        ...state,
        data: {
          isLoggedIn: true,
          firstname: action.payload['https://relaxcommerce.com/firstname'],
          lastname: action.payload['https://relaxcommerce.com/lastname'],
          hasNewsletterRequested:
            action.payload['https://relaxcommerce.com/hasNewsletterRequested'],
          isSocialSignin: action.payload['https://relaxcommerce.com/isSocialSignin'],
          email: action.payload.email,
        },
      }
    }
    default:
      return state
  }
}
