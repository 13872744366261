import { MediaSize } from 'modules/browser/types'
import { GridContext } from 'storybook/preprocessStory'
import { orderedMediaSizes } from 'modules/browser/const'

export type OptImg = {
  staticRatio: number | null
  src: string
  widths: Record<
    MediaSize,
    {
      scale: number
      width: number | 'full-width'
    }
  >
}

type Image = {
  transformedLink: string
  transformRatio: number | null
}

// for existing links, used with versionUpdate
export const transformExistingImgSrc = (src: string): string => {
  src = src.replace(/\n/g, '')
  /*   Überlegungen Modifiers
  f_modifiers: formatieren Bild - rausnehmen, würde das Modul machen
  fl_modifiers: komprimieren Bild - rausnehmen, würde das Modul machen
  vc_modifiers: video formatierung, werden allerdings keine Videos verwendet, kann raus

  g_modifiers: richten das bild aus, wenn die skalierung/aufteilung verändert werden soll
  c_modifiers: schneiden bilder zu (bspw. c_thump - erstellt ein thumbail und schneidet das bild zu)
  x_modifiers: legen x und y coords fest, ab welchem Punkt bilder beschnitten werden sollen
  a_modifiers: drehen bilder
  r_ runden kanten ab */
  /* das Problem ist, dass man kein Schema herauslesen kann, wenn der Modifier c_fill in Kombination mit w_
    und h_ verwendet wird, ob das Bild beschnitten wurde oder nur skaliert wurde, da dies nicht
    konsistent "korrekt" von Content verwendet wird
    bedeutet ich muss alle Kombinationen der 3 modifiers "blacklisten"
    auch wenn dadurch leider noch viele Links bestehen bleiben, die wir nicht optimieren
    wenn c_fill entweder mit w_ ODER mit h_ verwendet wird, wir das Bild nicht beschnitten
    gleiches gilt für c_scale in Kombination mit h_ ODER w_
    die Kombination c_scale mit h_ UND w_ führt zum "verziehen" des Bildes, das würde ich auch nicht akzeptieren
    gleiches gilt für h_ und w_ in Kombination ohne Verwendung von c_ modifier */
  let transformedLink = src
  const regEx = /(?:upload\/)([^#]+)(?=\/v1)/
  const match = regEx.exec(src)
  if (match !== null) {
    // einige modifiers sind doppelt vorhanden und mit mehreren slashes getrennt (warum auch immer), deshalb transform
    match[1].replace('/', ',')
    const transformed = match[1].replace(/\//g, ',')
    const arr = transformed.split(',')
    const transformedModifiers: any = []
    // c_crop und x_ / y_ haben definitiv das "ziel" das img zu beschneiden, bedeutet hier können wir nicht
    // die w_ und h_ modifiers entfernen
    if (
      transformed.includes('w_') &&
      transformed.includes('h_') &&
      (transformed.includes('c_fill') ||
        transformed.includes('c_crop') ||
        transformed.includes('c_thumb') ||
        transformed.includes('y_') ||
        transformed.includes('x_') ||
        transformed.includes('g_'))
    ) {
      for (let modifier in arr) {
        if (
          !arr[modifier].includes('f_') &&
          !arr[modifier].includes('fl_') &&
          !arr[modifier].includes('q_') &&
          !arr[modifier].includes('vc_')
        ) {
          transformedModifiers.push(arr[modifier])
        }
      }
      // der neue modifier (sofern vorhanden) ersetzt den bisherigen
      if (transformedModifiers.length > 0) {
        transformedLink = src.replace(regEx, 'upload/' + transformedModifiers)
        // sofern nach dem transform kein modifier vorhanden ist, wird der bisherige entfernt
      } else {
        transformedLink = src.replace(regEx, 'upload/' + '')
      }
    } else {
      // alle modifiers, die bisher verwendet wurden, und die keinen weiteren nutzen darstellen
      for (let modifier in arr) {
        if (
          !arr[modifier].includes('f_') &&
          !arr[modifier].includes('fl_') &&
          !arr[modifier].includes('q_') &&
          !arr[modifier].includes('vc_') &&
          !arr[modifier].includes('h_') &&
          !arr[modifier].includes('w_') &&
          !arr[modifier].includes('c_')
        ) {
          transformedModifiers.push(arr[modifier])
        }
      }
      if (transformedModifiers.length > 0) {
        transformedLink = src.replace(regEx, 'upload/' + transformedModifiers)
      } else {
        transformedLink = src.replace(regEx, 'upload/' + '')
      }
    }
  }
  // cut off double slash
  if (transformedLink.includes('upload//')) {
    return transformedLink.replace(/upload\/\//g, 'upload/')
  } else {
    return transformedLink
  }
}

// for preProcess to reduce load on client
const preProcessTransformImgSrc = (src: string): Image => {
  let transformedLink = src
  let transformRatio: number | null = null
  const regEx = /(?:upload\/)([^#]+)(?=\/v1)/
  const regWidth = /([Ww][_][0-9])/
  const regHeight = /([Hh][_][0-9])/
  const match = regEx.exec(src)
  if (match !== null) {
    match[1].replace('/', ',')
    const transformed = match[1].replace(/\//g, ',')
    const arr = transformed.split(',')
    const transformedModifiers: any = []
    if (
      transformed.includes('w_') &&
      transformed.includes('h_') &&
      (transformed.includes('c_pad') ||
        transformed.includes('c_crop') ||
        transformed.includes('c_thumb') ||
        transformed.includes('y_') ||
        transformed.includes('x_') ||
        transformed.includes('g_'))
    ) {
      // get w_ && h_modifier out of all modifiers and extract number
      let width = parseInt(arr[arr.findIndex((el) => el.match(regWidth))].replace(/[^0-9]/g, ''))
      let height = parseInt(arr[arr.findIndex((el) => el.match(regHeight))].replace(/[^0-9]/g, ''))
      transformRatio = width / height
      for (let modifier in arr) {
        if (
          !arr[modifier].includes('f_') &&
          !arr[modifier].includes('fl_') &&
          !arr[modifier].includes('q_') &&
          !arr[modifier].includes('vc_')
        ) {
          transformedModifiers.push(arr[modifier])
        }
      }
      if (transformedModifiers.length > 0) {
        transformedLink = src.replace(regEx, 'upload/' + transformedModifiers)
      } else {
        transformedLink = src.replace(regEx, 'upload/' + '')
      }
    } else {
      for (let modifier in arr) {
        if (
          !arr[modifier].includes('f_') &&
          !arr[modifier].includes('fl_') &&
          !arr[modifier].includes('q_') &&
          !arr[modifier].includes('vc_') &&
          !arr[modifier].includes('h_') &&
          !arr[modifier].includes('w_') &&
          !arr[modifier].includes('c_')
        ) {
          transformedModifiers.push(arr[modifier])
        }
      }
      if (transformedModifiers.length > 0) {
        transformedLink = src.replace(regEx, 'upload/' + transformedModifiers)
      } else {
        transformedLink = src.replace(regEx, 'upload/' + '')
      }
    }
  }
  if (transformedLink.includes('upload//')) {
    return {
      transformedLink: transformedLink.replace(/upload\/\//g, 'upload/'),
      transformRatio: transformRatio,
    }
  } else {
    return { transformedLink: transformedLink, transformRatio: transformRatio }
  }
}

export const transformImgSrc = (optImg: OptImg, ms: MediaSize) => {
  if (typeof window === 'undefined') return optImg.src

  const transform = (width: number, dpr: number): string => {
    const regEx = /(?:upload\/)([^#]+)(?=\/v[0-9])/
    const match = regEx.exec(optImg.src)
    // src has a width & height modifier
    if (optImg.staticRatio !== null) {
      const paramsMatch = optImg.src.match(/upload\/(^\/)+/)
      if (!paramsMatch) return optImg.src
      const addedParams = `f_auto,dpr_${dpr},w_${width},h_${Math.round(width / optImg.staticRatio)}`
      const params = paramsMatch[1]
      // remove old modifiers
      let newParams = params.replace(/,?f_auto|,?dpr_[0-9]+|,?[wWhH]_[0-9]+/g, '')

      // upload/f_auto,w_100/v1 -> ''
      if (newParams === '') newParams = addedParams
      // upload/f_auto,y_100,s_100/xyz -> ,y_100,s_100
      else if (newParams[0] === ',') newParams = addedParams + newParams
      // upload/y_100,f_auto,s_100/xyz -> y_100,s_100
      else newParams = addedParams + ',' + newParams

      return optImg.src.replace(params, newParams)
    }
    // src has modifiers
    else if (match !== null) {
      return optImg.src.replace('upload/', `upload/c_pad,f_auto,dpr_${dpr},w_${width},`)
    }
    // src has no modifiers
    else {
      return optImg.src.replace('upload/', `upload/w_${width},c_pad,f_auto,dpr_${dpr}/`)
    }
  }

  const dpr = window.devicePixelRatio
  const width = optImg.widths[ms]

  if (width.width === 'full-width') {
    const windowWidth = window.innerWidth
    const rest = windowWidth % 50
    return transform((windowWidth + 50 - rest) * width.scale, dpr)
  }

  return transform(width.width * width.scale, dpr)
}

export const createOptimizedImage = (
  src: string,
  gridContext: GridContext,
  config: {
    fullWidth?: boolean
    scale?: number | { [ms: string]: number }
  } = {}
): OptImg => {
  const { transformedLink, transformRatio } = preProcessTransformImgSrc(src)

  const getScale = (ms: string) => {
    let scale = 1
    if (config.scale) {
      if (typeof config.scale === 'number') scale = config.scale
      else scale = config.scale[ms] || 1
    }
    return scale
  }

  if (config.fullWidth) {
    return {
      staticRatio: transformRatio,
      src: transformedLink,
      widths: {
        MOBILE_M: {
          scale: getScale('MOBILE_M'),
          width: 'full-width',
        },
        MOBILE_L: {
          scale: getScale('MOBILE_L'),
          width: 'full-width',
        },
        TABLET: {
          scale: getScale('TABLET'),
          width: 'full-width',
        },
        LAPTOP: {
          scale: getScale('LAPTOP'),
          width: 'full-width',
        },
        LAPTOP_L: {
          scale: getScale('LAPTOP_L'),
          width: 'full-width',
        },
        LAPTOP_XL: {
          scale: getScale('LAPTOP_XL'),
          width: 'full-width',
        },
      },
    }
  }

  const getSize = (x: number, ms: string, originalMs?: string) => {
    if (!originalMs) originalMs = ms
    if (!gridContext.byMediaSize[ms]) {
      const prevMs = orderedMediaSizes[orderedMediaSizes.findIndex((s) => s === ms) - 1]
      return getSize(x, prevMs, originalMs)
    }
    const scale = getScale(originalMs)

    return {
      width: Math.round(
        (x / gridContext.byMediaSize[ms].allCols.length) * gridContext.byMediaSize[ms].colStretch -
          20
      ),
      scale: scale,
    }
  }

  return {
    staticRatio: transformRatio,
    src: transformedLink,
    widths: {
      MOBILE_M: getSize(375, 'MOBILE_M'),
      MOBILE_L: getSize(525, 'MOBILE_L'),
      TABLET: getSize(768, 'TABLET'),
      LAPTOP: getSize(990, 'LAPTOP'),
      LAPTOP_L: getSize(1200, 'LAPTOP_L'),
      LAPTOP_XL: getSize(1200, 'LAPTOP_XL'),
    },
  }
}

/* Das Vorgehen zum transformieren, der bisherigen gepflegten Links habe ich mit allen verfügbaren Links aus den 
 Page-data.json Daten getestet. (siehe scripts/analysis/cloudinarylinks)
 Mein Vorgehen: 
 1. Alle Organisms ausgelesen, die Img-Src enthalten ausgelesen. Notwendig für die Story-Struktur um die Links auszulesen
   ['Banner', 'Image', 'TextWithImage', 'MagazineArticleTeaserByIdWidget', 'ImageWithLinks', 'ImageWithLinksBanner',
   'CategoryBannerBorder', 'CategoryTeaserList', 'CategoryBanner', 'Suggestion', 'FullwidthHeroBanner',
   'CategoryBannerBorderHorizontal', 'TextWithImageGallery', 'CurratedSearchWidget', 'ImageGallery']
 2. ALLE Links die "cloudinary" enthalten aus der Page-Data auslesen
 3. Alle Modifiers auslesen, die in den Links vorhanden sind. Das Schema der Links ist immer gleich, der Modifier befindet sich
    zwischen den Wörtern upload/ und /v1 (bis auf wenige Ausnahmen)
 4. Alle Links die Modifiers enthalten herauslesen
 5. Alle Links transformieren (Vorgehen ähnlich wie in dieser Datei hier in der transformImageSource())
 
 Anzahl aller Links: 4622
 Anzahl aller Links die ein Modifier enthalten: 3770

 Anmerkung:
 24 Links rutschen durch, die nicht korrekt angepasst werden. Diese müssten nochmal manuell angepasst werden
 [
  'https://res.cloudinary.com/wucu/image/upload/v1617006261/Startseite/April21/20210329_MIAMODA_Shopempfehlung_v0.01_lh.jpg',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto/\n' +
    'v1494496121/magazine/Artikel/clinique_03.png',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto/\n' +
    'c_fill,h_600,w_400/v1508940595/magazine/ETERNA/DSC_4533.jpg',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto/\n' +
    'v1496150774/magazine/Schoenwild/jules_zizzi2.png',
  'https://res.cloudinary.com/wucu/image/upload/v1602840742/magazine/2020/Kleider%20f%C3%BCr%20Frauen%20mit%20Bauch/20201016_Figurtyp_H_Bauch_kaschieren_v0.01_lh.jpg',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto\n' +
    '/c_scale,w_500/v1545048521/magazine/kunstfell.jpg',
  'https://res.cloudinary.com/wucu/image/upload/v1616419751/magazine/2021/lagenlook/berlinerpummelfee_lagenlook.png',
  'https://res.cloudinary.com/wucu/image/upload/v1540896500/Insta%20Shop/med-euw3c.squarelovin.com-41.jpg',
  'https://res.cloudinary.com/wucu/image/upload/v1540896498/Insta%20Shop/med-euw3c.squarelovin.com-47.jpg',
  'https://res.cloudinary.com/wucu/image/upload/v1540896498/Insta%20Shop/med-euw3c.squarelovin.com-82.jpg',
  'https://res.cloudinary.com/wucu/image/upload/v1540896495/Insta%20Shop/med-euw3c.squarelovin.com-80.jpg',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto/\n' +
    'c_fill,h_480,w_480/v1522328101/Insta%20Shop/hey6.jpg',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto//features%2B/Health_sport/fitness_zuhause.png',
  'https://res.cloudinary.com/wucu/image/upload/v1578495793/magazine/2020/sheego_Sponsored%20Post_Sportkollektion/Design_ohne_Titel_6.png',
  'https://res.cloudinary.com/wucu/image/upload/v1578495794/magazine/2020/sheego_Sponsored%20Post_Sportkollektion/Design_ohne_Titel_5.png',
  'https://res.cloudinary.com/wucu/image/upload/v1500533225/magazine/FASHIONWEEK%202017/sheego/anna_scholz_03.png',
  'https://res.cloudinary.com/wucu/image/upload/v1500533107/magazine/FASHIONWEEK%202017/sheego/anna_scholz_02.png',
  'https://res.cloudinary.com/wucu/image/upload/v1500532679/magazine/FASHIONWEEK%202017/sheego/anna_scholz_01.png',
  'https://res.cloudinary.com/wucu/image/upload/v1612179399/Design_ohne_Titel_1_cach1b.png',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto/\n' +
    'c_fill,w_300/v1454674032/magazine/Grauer_Cardigan.jpg',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto/\n' +
    'c_scale,w_400/v1478190979/magazine/PSFD%202016/Zizzi/14191898_924242134376110_1052577139664072383_n.jpg',
  'https://res.cloudinary.com/wucu/image/upload/v1582795675/Pages/Ballkleider/20200121_Slider_O_Typ_Ballkleider_v0.03_lh.jpg',
  'https://res.cloudinary.com/wucu/image/upload/v1606303575/Design_ohne_Titel_1_alahl7.png',
  'https://res.cloudinary.com/wucu/image/upload/f_auto,fl_lossy,q_auto/v586164229/Pages/supportyourlocal/20200403_Support_your_local_v0.01_lh.jpg'
]
 */
