// @flow
import graphql from './graphql'
import * as t from '../types'
import config from 'config'
import jsonp from 'jsonp'
// import fetchCreateAlgoliaHelper from 'utils/fetchCreateAlgoliaHelper'
import { firescoutMockFn } from '@kaminrunde/cypress-firescout'

export type FetchWishlistResponse = {
  items: t.WishlistItem[]
  hasSubscription: boolean
  pendingSubscription: boolean
}

export type SubscribeToWishlistResponse = null
export type AddWishlistItemResponse = t.WishlistItem
export type RemoveWishlistItemResponse = null
export type FetchWishlistProductsResponse = t.WishlistProduct[]
export type FetchGroupedIdRelationsResponse = { [sku: string]: string }

export const fetchWishlist = firescoutMockFn('wishlist.fetchWishlist', async function fetchWishlist(
  wishlistId: string,
  email: string
): Promise<FetchWishlistResponse> {
  const { listId } = config.modules.mailchimp.wishlist
  const result = await graphql(`query {
      fetchWishlist(wishlistID: "${wishlistId}"){ 
        itemId:objectID
        product { 
          productId:_id 
          groupedId
        }
      }
      fetchSubscription(listID: "${listId}", subscriberEmail: "${email}"){
        activeSubscription
        subscriptionStatus
      }
    }`)

  return {
    items: result.fetchWishlist.map((row) => Object.assign({ itemId: row.itemId }, row.product)),
    hasSubscription: result.fetchSubscription.activeSubscription,
    pendingSubscription: result.fetchSubscription.subscriptionStatus === 'pending',
  }
})

export const subscribeToWishlist = firescoutMockFn(
  'wishlist.subscribeToWishlist',
  async function subscribeToWishlist(
    wishlistId: string,
    email: string
  ): Promise<SubscribeToWishlistResponse> {
    // call mailchimp endpoint
    const { listId, uId, subscribeApiEndpoint } = config.modules.mailchimp.wishlist
    const subscribeUrl = `${subscribeApiEndpoint}?u=${uId}&id=${listId}&EMAIL=${email}&WISHLIST=${wishlistId}`

    return new Promise((resolve, reject) => {
      jsonp(subscribeUrl, { param: 'c' }, (err, data) => {
        if (err) reject(err)
        if (data.result === 'error') reject(data.msg)
        else resolve(null)
      })
    })
  }
)

export const addWishlistItem = firescoutMockFn(
  'wishlist.addWishlistItem',
  async function addWishlistItem(
    wishlistId: string,
    productIdOrSku: string,
    isGrouped = false
  ): Promise<AddWishlistItemResponse> {
    const index = isGrouped ? config.indexes.products_inventory : config.indexes.products
    const result = await graphql(`
    mutation { 
      add2Wishlist(wishlistID:"${wishlistId}", productIDs:["${productIdOrSku}"], index: "${index}"){
        itemId:objectID
        product { 
          productId:_id 
          groupedId
        }
      }
    }
  `)
    const item = result.add2Wishlist[0]
    if (!item) {
      throw new Error('could not add wishlist-item')
    }
    return {
      itemId: item.itemId,
      groupedId: item.product.groupedId,
      productId: item.product.productId,
    }
  }
)

export const removeWishlistItem = firescoutMockFn(
  'wishlist.removeWishlistItem',
  async function removeWishlistItem(item: t.WishlistItem): Promise<RemoveWishlistItemResponse> {
    await graphql(`
      mutation { 
        deleteObjectIdsFromWishlist(objectIDs:["${item.itemId}"])
      }
    `)
    return null
  }
)

export const fetchWishlistProducts = firescoutMockFn(
  'wishlist.fetchWishlistProducts',
  async function fetchWishlistProducts(wishlistId: string): Promise<FetchWishlistProductsResponse> {
    const result = await graphql(`query {
      fetchWishlist(wishlistID: "${wishlistId}"){ 
        itemId:objectID
        wishlistID
        sku:variantID
        active:productActive
        product {
          objectID: _id 
          _tags
          imageMediumURL
          productPrice
          productPriceOld
          productManufacturerBrand
          productName
          shippingAndHandling
          merchantName
          isOnSale
          salePercentage
          wunderSizes
          productColor
          deliveryTime
          groupedId
          wunderCategories
        }
      }
    }`)

    return result.fetchWishlist.map((row) =>
      Object.assign({}, row.product, {
        disabled: !row.active,
        sku: row.sku,
      })
    )
  }
)

export async function fetchGroupedIdRelations(
  items: t.WishlistItem[]
): Promise<FetchGroupedIdRelationsResponse> {
  // const createHelper = await fetchCreateAlgoliaHelper()
  // const helper = createHelper(config.indexes.products_inventory, {
  //   disjunctiveFacets: ['groupedId'],
  //   attributesToRetrieve: ['groupedId', 'sku'],
  // })
  // items.forEach((item) => {
  //   item.groupedId && helper.addDisjunctiveFacetRefinement('groupedId', item.groupedId)
  // })
  // const { content }: any = await helper.searchOnce()
  // let result: any = {}
  // for (let hit of content.hits) {
  //   result[hit.sku] = hit.groupedId
  // }
  // return result

  return {}
}
