import * as t from '../types'
import * as a from '../actions'
import * as s from '../selectors'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  data: t.User
  setupFinished: boolean
  login: () => Record<string, unknown>
  logout: () => Record<string, unknown>
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'user',
  name: 'user/useUser',
  createCacheKey: () => '',
  mapState: (state) => ({
    data: s.getUser(state),
    setupFinished: s.setupFinished(state),
  }),
  mapDispatch: {
    login: a.login,
    logout: a.logout,
  },
  areStatesEqual: (a, b) => a.data === b.data && a.setupFinished === b.setupFinished,
}

export default function useUser(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
