import { addRule } from 'redux-ruleset'
import * as a from './actions'
import * as t from './const'
import { defaultState } from './reducer'
import { LOCATION_CHANGE } from 'modules/navigation'

addRule({
  id: 'ui/OPEN_PRODUCT_MODAL',
  target: LOCATION_CHANGE,
  output: t.OPEN_PRODUCT_MODAL,
  condition: (action) => action.payload.hash.includes('prod-'),
  delay: 100,
  consequence: (action) => {
    const [id] = action.payload.hash.match(/prod-([^&])+/) || []
    if (id) return a.openProductModal(id.replace('prod-', ''))
    else return
  },
})

addRule({
  id: 'ui/CLEAR_URL_AFTER_PRODUCT_MODAL_CLOSE',
  target: t.CLOSE_PRODUCT_MODAL,
  output: '#url-hash',
  consequence: () => {
    window.history.replaceState(null, '', window.location.pathname)
  },
})

addRule({
  id: 'ui/CLEAR_ON_NAVIGATE',
  target: LOCATION_CHANGE,
  output: t.CLEAR,
  condition: (_, { getState }) => getState().ui !== defaultState,
  consequence: () => a.clear(),
})
