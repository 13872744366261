// @flow
import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as actions from '../../actions'
import config from 'config'

type Item = {
  label: string
  link: string
  children?: Item[]
}

type Output = [null | Item, Item[], (e: Event, item: Item) => void, () => void]

export default function useCategories(defaultBack: (...args: any[]) => void): Output {
  const gq = useStaticQuery(graphql`
    query FashionDropdown {
      navigation(id: { eq: "1XszcXhcH6g2G2G44QkwEI-0" }) {
        dropdown {
          label
          link
          children {
            label
            link
          }
        }
      }
    }
  `)

  const [activeItem, setActiveItem] = React.useState<null | Item>(null)
  const subItems: Item[] = React.useMemo(() => {
    const allItem = {
      label: 'Alles anzeigen',
      link: activeItem ? activeItem.link : '/shop/bekleidung/',
    }
    const items = activeItem && activeItem.children ? activeItem.children : gq.navigation.dropdown
    const result = [allItem, ...items]
    if (!config.features.maleCategory) {
      return result.filter((item) => !item.link.includes('fuer-den-mann'))
    }
    return result
  }, [activeItem])

  const handleItemClick = React.useCallback(
    (e: Event, item: Item) => {
      let label = 'Shop > '
      if (activeItem) label += activeItem.label + ' > '
      label += item.label
      actions.clickItem(label)
      if (item.children) {
        e.preventDefault()
        setActiveItem(item)
      } else {
        // hooks stays always active so we need to clear state manually
        setActiveItem(null)
      }
    },
    [activeItem]
  )

  const handleBackClick = React.useCallback(() => {
    let label = 'Shop > '
    if (activeItem) label += activeItem.label + ' > '
    label += 'zurück'
    actions.clickItem(label)
    if (activeItem) setActiveItem(null)
    else defaultBack()
  }, [activeItem])

  return [activeItem, subItems, handleItemClick, handleBackClick]
}
