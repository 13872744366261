import * as React from 'react'
import * as t from '../types'
import * as a from '../actions'
import * as s from '../selectors'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  wishlistId: string
  data: t.WishlistProduct[]
  isFetching: boolean
  fetchError: null | string
  _fetch: typeof a.fetchWishlistProductsRequest
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'wishlist',
  name: 'wishlist/useWishlistProducts',
  createCacheKey: () => '',
  mapState: (state) => ({
    wishlistId: s.getWishlistId(state),
    data: s.getProducts(state),
    isFetching: s.isFetchingProducts(state),
    fetchError: s.getProductsFetchError(state),
  }),
  mapDispatch: {
    _fetch: a.fetchWishlistProductsRequest,
  },
  areStatesEqual: (a, b) => a.products === b.products && a.wishlistId === b.wishlistId,
}

export default function useWishlistProducts(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  const hasFetched = React.useRef(false)

  React.useEffect(() => {
    if (hasFetched.current) return
    if (hook.isFetching || !hook.wishlistId) return
    hook._fetch(hook.wishlistId)
    hasFetched.current = true
  }, [hook.isFetching, hook.wishlistId])

  return hook
}
