import * as at from './const'
import * as t from './types'

export const sendEvent = (event: any) => ({
  type: at.SEND_EVENT,
  payload: event,
})

export const sendBufferedEvent = (event: any, context: t.EventContext) => ({
  type: at.SEND_BUFFERED_EVENT,
  meta: { context },
  payload: event,
})

export type SendEvent = ReturnType<typeof sendEvent>
export type SendBufferedEvent = ReturnType<typeof sendBufferedEvent>

export type Action = SendEvent | SendBufferedEvent
