// @flow
import * as at from './const'
import * as t from './types'
import * as api from './utils/api'

export const setWishlistId = (wishlistId: string, email: string) => ({
  type: at.SET_WISHLIST_ID,
  meta: { email },
  payload: wishlistId,
})

export const fetchWishlistRequest = (wishlistId: string, email: string) => ({
  type: at.FETCH_REQUEST,
  meta: { wishlistId, email },
})

export const fetchWishlistSuccess = (
  wishlistId: string,
  email: string,
  data: api.FetchWishlistResponse
) => ({
  type: at.FETCH_SUCCESS,
  meta: { wishlistId, email },
  payload: data,
})

export const fetchWishlistFailure = (wishlistId: string, email: string, error: string) => ({
  type: at.FETCH_FAILURE,
  meta: { wishlistId, email },
  payload: error,
})

export const addWishlistListingItemRequest = (wishlistId: string, objectId: string) => ({
  type: at.ADD_LISTING_ITEM_REQUEST,
  meta: { wishlistId, objectId },
})

export const addWishlistListingItemSuccess = (
  wishlistId: string,
  objectId: string,
  item: api.AddWishlistItemResponse
) => ({
  type: at.ADD_LISTING_ITEM_SUCCESS,
  meta: { wishlistId, objectId },
  payload: item,
})

export const addWishlistListingItemFailure = (
  wishlistId: string,
  objectId: string,
  error: string
) => ({
  type: at.ADD_LISTING_ITEM_FAILURE,
  meta: { wishlistId, objectId },
  payload: error,
})

export const addWishlistGroupedItemRequest = (wishlistId: string, sku: string) => ({
  type: at.ADD_GROUPED_ITEM_REQUEST,
  meta: { wishlistId, sku },
})

export const addWishlistGroupedItemSuccess = (
  wishlistId: string,
  sku: string,
  item: api.AddWishlistItemResponse
) => ({
  type: at.ADD_GROUPED_ITEM_SUCCESS,
  meta: { wishlistId, sku },
  payload: item,
})

export const addWishlistGroupedItemFailure = (wishlistId: string, sku: string, error: string) => ({
  type: at.ADD_GROUPED_ITEM_FAILURE,
  meta: { wishlistId, sku },
  payload: error,
})

export const removeWishlistItem = (wishlistItem: t.WishlistItem) => {
  return {
    type: at.REMOVE_ITEM,
    payload: wishlistItem,
  }
}

export const fetchWishlistProductsRequest = (wishlistId: string) => ({
  type: at.FETCH_PRODUCTS_REQUEST,
  meta: { wishlistId },
})

export const fetchWishlistProductsSuccess = (
  wishlistId: string,
  products: api.FetchWishlistProductsResponse
) => ({
  type: at.FETCH_PRODUCTS_SUCCESS,
  meta: { wishlistId },
  payload: products,
})

export const fetchWishlistProductsFailure = (wishlistId: string, error: string) => ({
  type: at.FETCH_PRODUCTS_FAILURE,
  meta: { wishlistId },
  payload: error,
})

export const wishlistSubscribeRequest = (email: string, wishlistId: string) => ({
  type: at.SUBSCRIBE_REQUEST,
  meta: { email, wishlistId },
})

export const wishlistSubscribeSuccess = (
  email: string,
  wishlistId: string,
  response: api.SubscribeToWishlistResponse
) => ({
  type: at.SUBSCRIBE_SUCCESS,
  meta: { email, wishlistId },
  payload: response,
})

export const wishlistSubscribeFailure = (email: string, wishlistId: string, error: string) => ({
  type: at.SUBSCRIBE_FAILURE,
  meta: { email, wishlistId },
  payload: error,
})

export const rejectWishlistSubscription = () => ({
  type: at.REJECT_SUBSCRIPTION,
})

export const fetchGroupedIdRelationsRequest = (items: t.WishlistItem[]) => ({
  type: at.FETCH_GROUPED_ID_RELATIONS_REQUEST,
  meta: { items },
})

export const fetchGroupedIdRelationsSuccess = (
  items: t.WishlistItem[],
  result: api.FetchGroupedIdRelationsResponse
) => ({
  type: at.FETCH_GROUPED_ID_RELATIONS_SUCCESS,
  meta: { items },
  payload: result,
})

export const fetchGroupedIdRelationsFailure = (items: t.WishlistItem[], error: string) => ({
  type: at.FETCH_GROUPED_ID_RELATIONS_FAILURE,
  meta: { items },
  payload: error,
})

export type SetWishlistId = ReturnType<typeof setWishlistId>
export type FetchWishlistRequest = ReturnType<typeof fetchWishlistRequest>
export type FetchWishlistSuccess = ReturnType<typeof fetchWishlistSuccess>
export type FetchWishlistFailure = ReturnType<typeof fetchWishlistFailure>
export type AddWishlistListingItemRequest = ReturnType<typeof addWishlistListingItemRequest>
export type AddWishlistListingItemSuccess = ReturnType<typeof addWishlistListingItemSuccess>
export type AddWishlistListingItemFailure = ReturnType<typeof addWishlistListingItemFailure>
export type AddWishlistGroupedItemRequest = ReturnType<typeof addWishlistGroupedItemRequest>
export type AddWishlistGroupedItemSuccess = ReturnType<typeof addWishlistGroupedItemSuccess>
export type AddWishlistGroupedItemFailure = ReturnType<typeof addWishlistGroupedItemFailure>
export type RemoveWishlistItem = ReturnType<typeof removeWishlistItem>
export type FetchWishlistProductsRequest = ReturnType<typeof fetchWishlistProductsRequest>
export type FetchWishlistProductsSuccess = ReturnType<typeof fetchWishlistProductsSuccess>
export type FetchWishlistProductsFailure = ReturnType<typeof fetchWishlistProductsFailure>
export type WishlistSubscribeRequest = ReturnType<typeof wishlistSubscribeRequest>
export type WishlistSubscribeSuccess = ReturnType<typeof wishlistSubscribeSuccess>
export type WishlistSubscribeFailure = ReturnType<typeof wishlistSubscribeFailure>
export type RejectWishlistSubscription = ReturnType<typeof rejectWishlistSubscription>
export type FetchGroupedIdRelationsRequest = ReturnType<typeof fetchGroupedIdRelationsRequest>
export type FetchGroupedIdRelationsSuccess = ReturnType<typeof fetchGroupedIdRelationsSuccess>
export type FetchGroupedIdRelationsFailure = ReturnType<typeof fetchGroupedIdRelationsFailure>

export type Action =
  | SetWishlistId
  | FetchWishlistRequest
  | FetchWishlistSuccess
  | FetchWishlistFailure
  | AddWishlistListingItemRequest
  | AddWishlistListingItemSuccess
  | AddWishlistListingItemFailure
  | AddWishlistGroupedItemRequest
  | AddWishlistGroupedItemSuccess
  | AddWishlistGroupedItemFailure
  | RemoveWishlistItem
  | FetchWishlistProductsRequest
  | FetchWishlistProductsSuccess
  | FetchWishlistProductsFailure
  | WishlistSubscribeRequest
  | WishlistSubscribeSuccess
  | WishlistSubscribeFailure
  | RejectWishlistSubscription
  | FetchGroupedIdRelationsRequest
  | FetchGroupedIdRelationsSuccess
  | FetchGroupedIdRelationsFailure
