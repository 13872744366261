import { addRule } from 'redux-ruleset'
import * as a from './actions'
import * as at from './const'
import * as api from './utils/api'

addRule({
  id: 'user/INITIAL_SETUP',
  target: '*',
  output: [at.FINISH_SETUP, at.SET_USER],
  addOnce: true,
  consequence: async (_, { dispatch }) => {
    const user = await api.fetchUser()

    if (user) dispatch(a.setUser(user))

    dispatch(a.finishSetup())
  },
})

addRule({
  id: 'user/AUTH0_LOGIN',
  target: at.LOGIN,
  output: '#auth0-login',
  consequence: (action) => api.login(action.meta.url, action.meta.register),
})

addRule({
  id: 'user/AUTH0_LOGOUT',
  target: at.LOGOUT,
  output: '#auth0-logout',
  consequence: () => api.logout(),
})

addRule({
  id: 'user/AUTH0_REDIRECT',
  target: at.AUTH_0_REDIRECT,
  output: '#auth0-redirect',
  consequence: () => api.auth0Redirect(),
})
