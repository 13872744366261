// import config from 'config'
// import fetchCreateAlgoliaHelper from './fetchCreateAlgoliaHelper'

export type Entry = {
  id: string
  type: 'image' | 'carousel'
  link: string | null
  posting_time: number
  posting_text: string
  media: {
    original_url: string | null
    cropped_url: string // null if type === 'video'
    type: 'image' | 'video' | 'carousel'
  }[]
  hashtags: string[]
  product_ids: string[]
  allowed_time: null | number
  user_name: string
  user_profile: string
  user_image: string
  categories: string[]
  location: null
  products: any
}


export async function fetchEntries(page: number, tag?:string) {
  return {
    hits: [],
    nbHits: 0,
    nbPages: 0
  }
  // const createAlgoliaHelper = await fetchCreateAlgoliaHelper<Entry>()
  // const helper = createAlgoliaHelper(config.indexes.squarelovin, {
  //   disjunctiveFacets: ['categories'],
  //   hitsPerPage: 48
  // })

  // if(tag) helper.addDisjunctiveFacetRefinement('categories', tag)
  // helper.setPage(page)
  // const result = await helper.searchOnce()
  // return {
  //   hits: result.content.hits,
  //   nbHits: result.content.nbHits,
  //   nbPages: result.content.nbPages
  // }
}

export async function fetchEntry(id:string):Promise<Entry|null> {
  return null
  // const createAlgoliaHelper = await fetchCreateAlgoliaHelper<Entry>()
  // const helper = createAlgoliaHelper(config.indexes.squarelovin, {
  //   disjunctiveFacets: ['id'],
  //   hitsPerPage: 48
  // })
  
  // helper.addDisjunctiveFacetRefinement('id', id)
  // const result = await helper.searchOnce()
  // return result.content.hits[0] || null
}
