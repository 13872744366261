// @flow
import styled, { css } from 'styled-components'
import { ms } from 'modules/browser/const'
import pt from 'prop-types'

/**
You can escape the container boundings by adding the class '.full-width'
This only works for direct descendants of a Container and will stretch it to 100vw
Each media-size can individually stretched by by adding the classes:
- .full-width-MOBILE
- .full-width-MOBILE_L
- .full-width-TABLET
- .full-width-LAPTOP
- .full-width-LAPTOP_L
- .full-width-LAPTOP_XL

You can also use a smaller version ob the container by adding the prop 'small'
This will shrink the max-width on LAPTOP_L and LAPTOP_XL a little bit
This is used e.g on magazine-article-routes
 */

const Container = styled.div.attrs((props) => ({
  className: props.className ? `${props.className} Container` : `Container`,
}))`
  margin: 0 15px;
  max-width: ${(props) => (props.small ? ms.CONTAINER_SMALL : ms.CONTAINER)}px;
  width: calc(100vw - 30px);
  --max-container-size: ${(props) => (props.small ? ms.CONTAINER_SMALL : ms.CONTAINER)}px;

  .Container {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  @media (max-width: ${ms.TABLET}px) {
    --container-size: calc(100vw - 30px);
    .full-width,
    .full-width-MOBILE,
    .full-width-MOBILE_L {
      width: 100vw;
      margin: 0 calc(15px / -1);
    }
  }
  @media (min-width: ${ms.TABLET}px) {
    margin: 0 20px;
    width: calc(100vw - 40px);
    --container-size: calc(100vw - 40px);
    .full-width,
    .full-width-TABLET {
      width: 100vw;
      margin: 0 calc(20px / -1);
    }
  }
  @media (min-width: ${ms.LAPTOP}px) {
    .full-width-MOBILE,
    .full-width-MOBILE_L,
    .full-width-TABLET {
      width: 100%;
      margin-left: unset;
    }
    .full-width,
    .full-width-LAPTOP {
      width: 100vw;
      margin: 0 calc(20px / -1);
    }
  }
  ${(p) =>
    p.small &&
    css`
      @media (min-width: ${ms.CONTAINER_SMALL + 40}px) {
        --container-size: ${ms.CONTAINER_SMALL}px;
        margin: 0 auto;
      }
    `}
  @media (min-width: ${ms.LAPTOP_L}px) {
    .full-width-MOBILE,
    .full-width-MOBILE_L,
    .full-width-TABLET,
    .full-width-LAPTOP {
      width: 100%;
      margin: unset;
    }
    .full-width,
    .full-width-LAPTOP_L {
      width: 100vw;
      margin: ${(p) =>
        p.small ? `0 calc((100vw - ${ms.CONTAINER_SMALL}px) / -2)` : '0 calc(20px / -1)'};
    }
  }
  @media (min-width: ${ms.LAPTOP_XL}px) {
    ${(p) =>
      p.small ||
      css`
        --container-size: ${ms.CONTAINER}px;
      `}
    margin: 0 auto;
    width: ${(props) => (props.small ? ms.CONTAINER_SMALL : ms.CONTAINER)}px;
    .full-width-MOBILE,
    .full-width-MOBILE_L,
    .full-width-TABLET,
    .full-width-LAPTOP,
    .full-width-LAPTOP_L {
      width: 100%;
      margin: unset;
    }
    .full-width,
    .full-width-LAPTOP_XL {
      width: 100vw;
      margin: 0
        calc((100vw - ${(props) => (props.small ? ms.CONTAINER_SMALL : ms.CONTAINER)}px) / -2);
    }
  }
`

Container.propTypes = {
  small: pt.bool,
  className: pt.string,
}

export default Container
