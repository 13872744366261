function getInternetExplorerVersion() {
  let rV = -1 // Return value assumes failure.

  if (navigator.appName === 'Microsoft Internet Explorer' || navigator.appName === 'Netscape') {
    let uA = navigator.userAgent
    let rE = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})')

    if (rE.exec(uA) != null) {
      rV = parseFloat(RegExp.$1)
    } else if (navigator.userAgent.match(/Trident.*rv:11\./)) {
      /*check for IE 11*/
      rV = 11
    }
  }
  return rV
}

function areYouIE() {
  if (typeof window === 'undefined') return false
  if (getInternetExplorerVersion() !== -1) {
    const tag = `
            <div id='overlay-ie' style="position:fixed;left:0;top:0;right:0;bottom:0;background:rgba(0,0,0,0.8);z-index:9999999999;"></div>
            <div id='modal-ie' style="top:0;left:50%;background:white;width:600px;position:fixed;margin-left:-300px;margin-top:100px;border:2px solid grey;padding:20px;z-index:9999999999;">
                <div class='title'>
                    <strong>Veraltete Browserversionen werden nicht unterstützt.</strong>
                </div>
                <div class='message'>
                    <br/>
                    Liebe Besucherin, lieber Besucher auf Wundercurves, 
                    <br/><br/>
                    Du verwendest eine veraltete Version des Internet Explorer. Mit diesem Browser lässt sich unsere Webseite nicht nutzen.
                    <br/><br/>
                    Um Wundercurves (und viele weitere Webseiten) in vollem Umfang und sicher nutzen zu können, 
                    empfehlen wir Dir, deinen Browser zu aktualisieren.
                    <br/><br/>
                    Wir unterstützen unter anderem folgende Browser:
                    <br/><br/>
                    <ul>
                        <li><a href="https://www.google.de/chrome/">Google Chrome</a></li>
                        <li><a href="https://www.mozilla.org/de/firefox/">Firefox</a></li>
                        <li>Microsoft Edge</li>
                    </ul>
                </div>
            </div>
        `
    const el = document.createElement('div')
    el.id = 'ie-alert'
    el.innerHTML = tag
    document.body.appendChild(el)
    return true
  }
  return false
}

areYouIE()
