import * as React from 'react'
import throttleFn from 'utils/throttle'

export default function useWindowEvent<K extends keyof WindowEventMap>(
  condition: boolean,
  event: K,
  cb: (ev: WindowEventMap[K]) => any,
  options: {
    throttle?: number
    initialCall?: boolean
  } = {}
): void {
  React.useEffect(() => {
    if (!condition) return
    const l = options.throttle ? throttleFn(cb, options.throttle) : cb
    if (options.initialCall) l('inital-call' as any)
    window.addEventListener(event, l)
    return () => window.removeEventListener(event, l)
  }, [condition, cb, options.throttle, options.initialCall])
}
