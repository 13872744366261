export const SET_WISHLIST_ID: 'wishlist/SET_WISHLIST_ID' = 'wishlist/SET_WISHLIST_ID'
export const REMOVE_ITEM: 'wishlist/REMOVE_ITEM' = 'wishlist/REMOVE_ITEM'
export const FETCH_REQUEST: 'wishlist/FETCH_REQUEST' = 'wishlist/FETCH_REQUEST'
export const FETCH_SUCCESS: 'wishlist/FETCH_SUCCESS' = 'wishlist/FETCH_SUCCESS'
export const FETCH_FAILURE: 'wishlist/FETCH_FAILURE' = 'wishlist/FETCH_FAILURE'
export const ADD_LISTING_ITEM_REQUEST: 'wishlist/ADD_LISTING_ITEM_REQUEST' =
  'wishlist/ADD_LISTING_ITEM_REQUEST'
export const ADD_LISTING_ITEM_SUCCESS: 'wishlist/ADD_LISTING_ITEM_SUCCESS' =
  'wishlist/ADD_LISTING_ITEM_SUCCESS'
export const ADD_LISTING_ITEM_FAILURE: 'wishlist/ADD_LISTING_ITEM_FAILURE' =
  'wishlist/ADD_LISTING_ITEM_FAILURE'
export const ADD_GROUPED_ITEM_REQUEST: 'wishlist/ADD_GROUPED_ITEM_REQUEST' =
  'wishlist/ADD_GROUPED_ITEM_REQUEST'
export const ADD_GROUPED_ITEM_SUCCESS: 'wishlist/ADD_GROUPED_ITEM_SUCCESS' =
  'wishlist/ADD_GROUPED_ITEM_SUCCESS'
export const ADD_GROUPED_ITEM_FAILURE: 'wishlist/ADD_GROUPED_ITEM_FAILURE' =
  'wishlist/ADD_GROUPED_ITEM_FAILURE'
export const FETCH_PRODUCTS_REQUEST: 'wishlist/FETCH_PRODUCTS_REQUEST' =
  'wishlist/FETCH_PRODUCTS_REQUEST'
export const FETCH_PRODUCTS_SUCCESS: 'wishlist/FETCH_PRODUCTS_SUCCESS' =
  'wishlist/FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAILURE: 'wishlist/FETCH_PRODUCTS_FAILURE' =
  'wishlist/FETCH_PRODUCTS_FAILURE'
export const SUBSCRIBE_REQUEST: 'wishlist/SUBSCRIBE_REQUEST' = 'wishlist/SUBSCRIBE_REQUEST'
export const SUBSCRIBE_SUCCESS: 'wishlist/SUBSCRIBE_SUCCESS' = 'wishlist/SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_FAILURE: 'wishlist/SUBSCRIBE_FAILURE' = 'wishlist/SUBSCRIBE_FAILURE'
export const REJECT_SUBSCRIPTION: 'wishlist/REJECT_SUBSCRIPTION' = 'wishlist/REJECT_SUBSCRIPTION'
export const FETCH_GROUPED_ID_RELATIONS_REQUEST: 'wishlist/FETCH_GROUPED_ID_RELATIONS_REQUEST' =
  'wishlist/FETCH_GROUPED_ID_RELATIONS_REQUEST'
export const FETCH_GROUPED_ID_RELATIONS_SUCCESS: 'wishlist/FETCH_GROUPED_ID_RELATIONS_SUCCESS' =
  'wishlist/FETCH_GROUPED_ID_RELATIONS_SUCCESS'
export const FETCH_GROUPED_ID_RELATIONS_FAILURE: 'wishlist/FETCH_GROUPED_ID_RELATIONS_FAILURE' =
  'wishlist/FETCH_GROUPED_ID_RELATIONS_FAILURE'
