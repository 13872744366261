// @flow
import './rules'
import store from 'store'
import reducer, { State } from './reducer'
import { Action } from './actions'

store.injectReducer(store, 'ui', reducer)

declare global {
  interface RootState {
    ui: State
  }
  interface RootReducers {
    ui: typeof reducer
  }
  interface ModuleActions {
    ui: Action
  }
}

export { default as useCartStatus } from './hooks/useCartStatus'
export { default as useSidebarStatus } from './hooks/useSidebarStatus'
export { default as useProductModalId } from './hooks/useProductModalId'
export { default as useSquarelovinId } from './hooks/useSquarelovinId'
