// @flow
import * as at from './const'
import * as t from './types'

export const setCartOpen = (open: boolean) => ({
  type: at.SET_CART_OPEN,
  payload: open,
})

export const setSidebarOpen = (open: boolean) => ({
  type: at.SET_SIDEBAR_OPEN,
  payload: open,
})

export const openProductModal = (objectId: string) => ({
  type: at.OPEN_PRODUCT_MODAL,
  payload: objectId,
})

export const closeProductModal = () => ({
  type: at.CLOSE_PRODUCT_MODAL,
})

export const setSidebarComponent = (component: t.SidebarComponent) => ({
  type: at.SET_SIDEBAR_COMPONENT,
  payload: component,
})

export const setSquarelovinId = (id: string | null) => ({
  type: at.SET_SQUARELOVIN_ID,
  payload: id,
})

export const clear = () => ({
  type: at.CLEAR,
})

export type SetCartOpen = ReturnType<typeof setCartOpen>
export type SetSidebarOpen = ReturnType<typeof setSidebarOpen>
export type OpenProductModal = ReturnType<typeof openProductModal>
export type CloseProductModal = ReturnType<typeof closeProductModal>
export type Clear = ReturnType<typeof clear>
export type SetSidebarComponent = ReturnType<typeof setSidebarComponent>
export type SetSquarelovinId = ReturnType<typeof setSquarelovinId>

export type Action =
  | SetCartOpen
  | SetSidebarOpen
  | OpenProductModal
  | CloseProductModal
  | Clear
  | SetSidebarComponent
  | SetSquarelovinId
