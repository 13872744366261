// @flow
import * as React from 'react'
import { Link as GatsbyLink, navigate as _navigate } from 'gatsby'
import config from 'config'
import formatUrl from 'utils/formatUrl'
import { adminMode } from 'config/utils'

/**
This should be the replacement of gatsby's internal Link since it add serveral functionalities
that the original Link component does not handle:
- if not to prop exists a normal span with a-like styling is rendered
- if to prop is external link it will render a normal a tag (with rel noopener...)
- if to prop is anchor link (#my-id)  it will render a normal a tag
- a trailing slash is added none is present
- the to prop is formatted lower case
- the url is prefixed by it's location (on .com a '/de-at' is prefixed to each link)
 */

type Props = {
  to?: string | false | null
  children: React.ReactNode
  sametab?: boolean
  withHash?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  'data-cy-handle'?: string
  'data-cy-ctx'?: string
  className?: string
  noFollow?: boolean
  style?: Record<string, unknown>
}

// @ts-ignore
export default React.forwardRef<Props>(function Link(props: Props, ref: any) {
  let { to = '', children, sametab, withHash } = props
  if (!to) {
    let className = 'a-like' + props.className ? ' ' + props.className : ''
    return (
      <span
        ref={ref}
        onClick={props.onClick}
        className={className}
        style={props.style}
        data-cy-ctx={props['data-cy-ctx']}
        data-cy-handle={props['data-cy-handle']}
        children={children}
      />
    )
  }
  const isRelativeLink = to[0] === '/'
  const isExternalLink = to.startsWith('http')
  const isNoFollowLink = props.noFollow || to.startsWith('nofollow:')
  const isAnchorLink = isRelativeLink && !!to.match(/#[^=^&]*$/)

  if (isNoFollowLink) {
    return (
      <a
        ref={ref}
        onClick={props.onClick}
        className={props.className}
        style={props.style}
        data-cy-handle={props['data-cy-handle']}
        data-cy-ctx={props['data-cy-ctx']}
        rel="nofollow noopener noreferrer"
        href={to.replace('nofollow:', '')}
        target="_blank"
      >
        {children}
      </a>
    )
  }

  if (isExternalLink) {
    const target = sametab ? undefined : '_blank'
    return (
      <a
        ref={ref}
        onClick={props.onClick}
        className={props.className}
        style={props.style}
        data-cy-handle={props['data-cy-handle']}
        data-cy-ctx={props['data-cy-ctx']}
        rel="noopener noreferrer"
        href={to}
        target={target}
      >
        {children}
      </a>
    )
  }

  if (isAnchorLink) {
    return (
      <a
        ref={ref}
        onClick={props.onClick}
        className={props.className}
        style={props.style}
        data-cy-handle={props['data-cy-handle']}
        data-cy-ctx={props['data-cy-ctx']}
        href={config.prefix + to.toLowerCase()}
      >
        {children}
      </a>
    )
  }

  if (!isRelativeLink) {
    return (
      <a
        ref={ref}
        onClick={props.onClick}
        className={props.className}
        style={props.style}
        data-cy-handle={props['data-cy-handle']}
        data-cy-ctx={props['data-cy-ctx']}
        href={to}
      >
        {children}
      </a>
    )
  }

  const path = formatUrl(to)

  return (
    <GatsbyLink
      ref={ref}
      className={props.className}
      children={props.children}
      data-cy-handle={props['data-cy-handle']}
      data-cy-ctx={props['data-cy-ctx']}
      to={path}
      style={props.style}
      onClick={(e) => {
        if (props.onClick) props.onClick(e)
        // '#' urls do not reset scroll position
        // because gatsby handles them as anchor-links
        if(path.includes('#q=')) window.scrollTo({top:0, behavior: 'auto'})
        if (withHash) {
          e.preventDefault()
          // @ts-ignore
          _navigate(path + window.location.hash, props)
        }
      }}
    />
  )
}) as React.ComponentType<Props>

export const navigate = (to: string, options?: Record<string, unknown>) => {
  adminMode && console.log('navigate', config.prefix + to)
  return _navigate(config.prefix + to, options)
}
