
let prevLocation = null
// store/rules/locationChange
exports.onPreRouteUpdate = () => {
  const location = window.location
  window.___triggerGatsbyLocationChange(prevLocation, location)
  prevLocation = {...location}
}

exports.shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  if (prevRouterProps && routerProps.location.pathname === prevRouterProps.location.pathname) {
    return false
  }
  if (routerProps.location.state && routerProps.location.state.keepScrollPosition) {
    return false
  }
}
