import * as React from 'react'
import styled, { css } from 'styled-components'
import Icon from './Icon'
import SearchForm from './SearchForm'
import { FiMenu } from 'react-icons/fi'
import WucuLogo from 'svg/WucuLogo'
import useWindowEvent from 'hooks/useWindowEvent'
import Container from 'theme/atoms/Container'
import Link from 'theme/atoms/Link'
import { ms } from 'modules/browser/const'
import { useSidebarStatus } from 'modules/ui'
import WishlistIcon from './WishlistIcon'
import UserIcon from './UserIcon'
import config from 'config'

const wishlistEnabled = config.features.wishlist
const userEnabled = config.checkout.enabled

export default function Header() {
  const [showSearch, setShowSearch] = React.useState(false)
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const sidebar = useSidebarStatus()

  useWindowEvent(
    true,
    'scroll',
    React.useCallback(() => {
      const offset = window.pageYOffset
      if (offset > 0 && !hasScrolled) setHasScrolled(true)
      if (offset < 1 && hasScrolled) setHasScrolled(false)
    }, [hasScrolled]),
    { throttle: 200 }
  )

  return (
    <Wrapper showSearch={showSearch} hasScrolled={hasScrolled} data-cy-ctx="core/Header">
      <div className="row-1">
        <Container>
          <div className="scroll-menu">
            <Icon
              icon={FiMenu}
              onClick={() => sidebar.setOpen(true)}
              data-cy-handle="scroll-burger-menu"
              data-cy-state={hasScrolled ? 'scroll-header' : undefined}
            />
            <Link to="/" className="logo-wrapper">
              <WucuLogo fill="white" />
            </Link>
          </div>
          <SearchForm showSearch={showSearch} setShowSearch={setShowSearch} />
          {userEnabled && <UserIcon data-cy-state="user-enabled:user-icon" />}
          {wishlistEnabled && <WishlistIcon data-cy-state="wishlist-enabled" />}
        </Container>
      </div>
      <div className="row-2">
        <Container>
          <FiMenu
            className="menu"
            onClick={() => sidebar.setOpen(true)}
            data-cy-handle="burger-menu"
          />
          <Link to="/">
            <WucuLogo fill="#993351" />
          </Link>
          <ul>
            <li>
              <Link to="/shop/bekleidung/">Shop</Link>
            </li>
            <li>
              <Link to="/page/looks/">Inspiration</Link>
            </li>
            <li>
              <Link to="/page/beratung-grosse-groessen/">Beratung</Link>
            </li>
          </ul>
        </Container>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  padding-top: 40px;
  @media (min-width: 800px) {
    padding-top: 70px;
  }
  > .row-1 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999997;
    background: #993452;

    > .Container {
      height: 40px;
      @media (min-width: 800px) {
        height: 70px;
      }
      display: flex;
      justify-content: center;
      align-items: center;

      > .scroll-menu {
        width: 0%;
        height: 100%;
        opacity: 0;
        overflow: hidden;
        transition: width 300ms ease-out, opacity 300ms ease-out;
        display: flex;
        align-items: center;
        @media (min-width: 800px) {
          transition: none;
          opacity: 1;
          width: 100%;
        }
        > .Icon {
          display: block;
          @media (min-width: 800px) {
            margin-left: -50px;
            margin-right: 20px;
            margin-top: 5px;
            transition: margin-left 300ms ease-out;
            ${(p) =>
              p.hasScrolled &&
              css`
                margin-left: 0;
              `}
          }
        }
        > .logo-wrapper {
          display: flex;
          > .WucuLogo {
            display: none;
            margin-left: 10px;
            @media (min-width: 360px) {
              width: 120px;
              display: block;
            }
            @media (min-width: 420px) {
              width: 150px;
            }
            @media (min-width: 800px) {
              width: 200px;
              margin-left: 0;
            }
          }
        }

        ${(p) =>
          p.hasScrolled &&
          !p.showSearch &&
          css`
            width: 100%;
            opacity: 1;
          `}
      }

      > .Icon {
        margin-left: 40px;
        transition: margin-left 300ms ease-out;
        &:first-child {
          margin-left: 0;
        }
        ${(p) =>
          p.hasScrolled &&
          css`
            margin-left: 15px;
          `}
        ${(p) =>
          p.showSearch &&
          css`
            margin-left: 10px;
          `}

        @media (min-width: 800px) {
          transition: none;
          margin-left: 30px;
        }
      }
    }
  }

  > .row-2 {
    background: white;
    box-shadow: 0px -5px 15px 5px rgba(0, 0, 0, 0.2);
    position: relative;

    > .Container {
      height: 50px;
      display: flex;

      > .menu {
        font-size: 30px;
        margin: 0 15px;
        margin-top: 10px;
        color: #993452;
        cursor: pointer;
        @media (min-width: 800px) {
          margin-left: 0;
        }
      }

      > a > .WucuLogo {
        max-width: 170px;
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
        @media (min-width: 800px) {
          display: none;
        }
      }

      > ul {
        margin: 0;
        list-style: none;
        align-items: center;
        padding-left: 10px;
        display: none;
        @media (min-width: 800px) {
          display: flex;
        }
        @media (min-width: ${ms.LAPTOP_L}px) {
          padding-left: 30px;
        }

        > li > a {
          color: #555555;
          font-family: Raleway;
          font-size: 15px;
          letter-spacing: 1.7px;
          line-height: 25px;
          text-transform: uppercase;
          margin-right: 25px;
          @media (min-width: ${ms.LAPTOP_L}px) {
            margin-right: 45px;
          }
          &.sale {
            color: #c8402a;
          }
        }
      }
    }
  }
`
