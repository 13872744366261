import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  auth0Redirect: typeof a.auth0Redirect
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'user',
  name: 'user/useRedirect',
  createCacheKey: () => '',
  mapState: () => ({}),
  mapDispatch: {
    auth0Redirect: a.auth0Redirect,
  },
}

export default function useRedirect(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
