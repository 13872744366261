import { dispatchEvent } from 'redux-ruleset'

export const TRIGGER_SEARCH: 'Header/TRIGGER_SEARCH' = 'Header/TRIGGER_SEARCH'

export const triggerSearch = (search: string) =>
  dispatchEvent({
    type: TRIGGER_SEARCH,
    payload: search,
  })

export type TriggerSearch = ReturnType<typeof triggerSearch>

declare global {
  interface RulesetDispatchEvents {
    'core/Header': TriggerSearch
  }
}
