import './rules'
import store from 'store'
import reducer, { State } from './reducer'
import { Action } from './actions'

store.injectReducer(store, 'wishlist', reducer)

declare global {
  interface RootState {
    wishlist: State
  }
  interface RootReducers {
    wishlist: typeof reducer
  }
  interface ModuleActions {
    wishlist: Action
  }
}

export * from './actions'
export * from './selectors'
export * from './types'
export * from './const'

export { default as useWishlistCount } from './hooks/useWishlistCount'
export { default as useWishlistGroupedItem } from './hooks/useWishlistGroupedItem'
export { default as useWishlistListingItem } from './hooks/useWishlistListingItem'
export { default as useWishlistProducts } from './hooks/useWishlistProducts'
export { default as useWishlistSubscription } from './hooks/useWishlistSubscription'

export default reducer
