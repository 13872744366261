import * as t from '../types'
import * as a from '../actions'
import * as s from '../selectors'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  data: t.Wishlist
  wishlistId: string
  subscribe: (email: string) => void
  rejectSubscription: () => void
}

type SP = {
  subscribe: typeof a.wishlistSubscribeRequest
  rejectSubscription: typeof a.rejectWishlistSubscription
}

const config: Config<Input, Output, State, SP> = {
  moduleKey: 'wishlist',
  name: 'wishlist/useWishlistSubscription',
  createCacheKey: () => '',
  mapState: (state) => ({
    wishlistId: s.getWishlistId(state),
    data: s.getWishlist(state),
  }),
  mapDispatch: {
    subscribe: a.wishlistSubscribeRequest,
    rejectSubscription: a.rejectWishlistSubscription,
  },
  transformDispatch: {
    subscribe: (fn, sp) => (email) => fn(email, sp.wishlistId),
  },
  areStatesEqual: (a, b) => a.data === b.data && a.wishlistId === b.wishlistId,
}

export default function useWishlistSubscription(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
