import config from 'config'
import { firescoutMockFn } from '@kaminrunde/cypress-firescout'
import { Auth0User } from './auth0'

export type FetchUserResponse = Auth0User | null

const fetchAuth0 = () => {
  return import(/* webpackChunkName: "auth0" */ './auth0')
}

export async function login(redirectUrl: string, signup: boolean) {
  if (!config.checkout.enabled) return
  const auth0 = await fetchAuth0()
  const client = await auth0.fetchClient()
  const options = auth0.createLoginOptions(redirectUrl, signup)
  window.localStorage.setItem('wucu-logged-in', 'true')
  client.loginWithRedirect(options)
}

export const fetchUser = firescoutMockFn('user.fetchUser', async function fetchUser(): Promise<
  FetchUserResponse
> {
  if (!config.checkout.enabled) return null
  if (typeof window === 'undefined') return null
  if (!window.localStorage.getItem('wucu-logged-in')) return null

  const auth0 = await fetchAuth0()
  const client = await auth0.fetchClient()
  const user = await client.getUser()

  return user
})

export async function logout() {
  if (!config.checkout.enabled) return
  const auth0 = await fetchAuth0()
  const client = await auth0.fetchClient()
  client.logout()
  window.localStorage.removeItem('wucu-logged-in')
}

export async function auth0Redirect() {
  if (!config.checkout.enabled) return
  if (
    window.location.href.indexOf('?code=') === -1 ||
    window.location.href.indexOf('&state=') === -1
  ) {
    console.error('auth0Redirect: code or state not in url')
    return
  }
  const auth0 = await fetchAuth0()
  const client = await auth0.fetchClient()
  const { appState } = await client.handleRedirectCallback()
  if (appState && appState.targetUrl) {
    window.top.location.href = appState.targetUrl
  } else {
    window.top.location.href = window.top.location.origin
  }
}
