import { createGlobalStyle } from 'styled-components'
import './Layout.css'

export default createGlobalStyle`

  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: overlay;
  }

  #nprogress {
    position: relative;
    z-index: 9999999999999999999999;
  }

  body {
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.42px;
    line-height: 20px;
    color: #555;
    background-color: #fff;
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;
  } 

  button {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
  }

  p {
    margin: 10px 0;
  }

  input {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
  }

  strong {
    color: rgb(85, 85, 85);
    font-weight: 700;
  }

  a {
    color: #993452;
    text-decoration: none;
  }


  a,a:focus,a:hover {
    color: #993452;
    text-decoration: none;
  }

  a:focus {
    outline-style: dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    line-height: 1.1;
  }
  h4 { font-weight: bold; }

  h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  @media (max-width: 767px) {
    .h1,.h2,h1,h2 {
        font-size:18px;
    }
  }

  @media (min-width: 768px) {
    .h1,.h2,h1,h2 {
        font-size:22px
    }
  }

  @media (min-width: 1200px) {
    .h1,.h2,h1,h2 {
        font-size:26px;
    }
  }

  @media (max-width: 767px) {
    .h3,h3 {
        font-size:16px;
    }
  }

  @media (min-width: 768px) {
    .h3,h3 {
        font-size:20px;
    }
  }

  @media (min-width: 1200px) {
    .h3,h3 {
        font-size:24px;
    }
  }

  @media (max-width: 767px) {
    .h5,h5 {
        font-size:10px;
    }
  }

  @media (min-width: 768px) {
    .h5,h5 {
        font-size:12px;
    }
  }

  @media (min-width: 1200px) {
    .h5,h5 {
        font-size:16px;
    }
  }

`
