import { combineReducers } from 'redux'

declare global {
  interface RootReducers {}
  interface RootState {}
  interface ModuleActions {}
  type ValueOf<T> = T[keyof T]
  type ThenArg<T> = T extends PromiseLike<infer U> ? U : T
  type RootAction = ValueOf<ModuleActions> | { type: 'PARTIAL_STATE_UPDATES'; payload: any }

  type RemoveArg0<Fn> = Fn extends (arg0: any) => infer R
    ? () => R
    : never | Fn extends (arg0: any, ...args: infer T) => infer R
    ? (...args: T) => R
    : never
}

const createRootReducer = (reducers: RootReducers) =>
  function rootReducer(state: RootState, action: RootAction) {
    if (action.type === 'PARTIAL_STATE_UPDATES') {
      const updates = action.payload
      let newState = Object.assign({}, state)

      for (let update of updates) {
        let subState = newState
        for (let i = 0; i < update.path.length - 1; i++) {
          subState[update.path[i]] = Object.assign({}, subState[update.path[i]])
          if (i !== update.path.length) subState = subState[update.path[i]]
        }
        subState[update.path[update.path.length - 1]] = update.state
      }
      return newState
    }
    // @ts-ignore
    return combineReducers<RootState>(
      // @ts-ignore
      reducers || {
        foo: () => 'bar',
      }
    )(state, action)
  }

export function injectReducer(
  store: RootStore,
  key: keyof RootReducers,
  reducer: ValueOf<RootReducers>
) {
  if (store.asyncReducers[key]) return
  store.asyncReducers[key] = reducer
  const rootReducer = createRootReducer(store.asyncReducers)
  store.replaceReducer(rootReducer)
}

export default createRootReducer
