import { ms } from '../const'
import * as t from '../types'

export default function calcMediaSize(): t.MediaSize {
  if (typeof window === 'undefined') return 'MOBILE_M'
  const width = window.innerWidth

  if (width > ms.LAPTOP_XL) return 'LAPTOP_XL'
  if (width > ms.LAPTOP_L) return 'LAPTOP_L'
  if (width > ms.LAPTOP) return 'LAPTOP'
  if (width > ms.TABLET) return 'TABLET'
  if (width > ms.MOBILE_L) return 'MOBILE_L'
  return 'MOBILE_M'
}
