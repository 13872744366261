// @flow
import * as at from './const'
import { FetchUserResponse } from './utils/api'

export const finishSetup = () => ({
  type: at.FINISH_SETUP,
})

export const login = (url?: string | null, register = false) => ({
  type: at.LOGIN,
  meta: {
    url: url || window.location.href,
    register,
  },
})

export const logout = () => ({
  type: at.LOGOUT,
})

export const setUser = (user: FetchUserResponse) => ({
  type: at.SET_USER,
  payload: user,
})

export const auth0Redirect = () => ({
  type: at.AUTH_0_REDIRECT,
})

export type FinishSetup = ReturnType<typeof finishSetup>
export type Login = ReturnType<typeof login>
export type Logout = ReturnType<typeof logout>
export type SetUser = ReturnType<typeof setUser>
export type Auth0Redirect = ReturnType<typeof auth0Redirect>

export type Action = FinishSetup | Login | Logout | SetUser | Auth0Redirect
