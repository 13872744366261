import { addRule } from 'redux-ruleset'
import * as api from './utils/api'
import * as a from './actions'
import * as at from './const'
import * as s from './selectors'

addRule({
  id: 'user/wishlist/TRIGGER_FETCH',
  target: at.SET_WISHLIST_ID,
  output: at.FETCH_REQUEST,
  condition: (action) => Boolean(action.payload),
  consequence: (action) => a.fetchWishlistRequest(action.payload, action.meta.email),
})

addRule({
  id: 'user/wishlist/FETCH',
  target: at.FETCH_REQUEST,
  output: [at.FETCH_SUCCESS, at.FETCH_FAILURE],
  concurrency: 'LAST',
  consequence: (action) => {
    const { wishlistId, email } = action.meta
    return api.fetchWishlist(wishlistId, email).then(
      (result) => a.fetchWishlistSuccess(wishlistId, email, result),
      (error) => a.fetchWishlistFailure(wishlistId, email, error.toString())
    )
  },
})

addRule({
  id: 'user/wishlist/TRIGGER_FETCH_GROUPED_ID_RELATIONS',
  target: [
    at.FETCH_SUCCESS,
    at.ADD_GROUPED_ITEM_SUCCESS,
    at.ADD_LISTING_ITEM_SUCCESS,
    at.REMOVE_ITEM,
  ],
  output: at.FETCH_GROUPED_ID_RELATIONS_REQUEST,
  consequence: (_, { getState }) => {
    const state = getState()
    const items = s.getAllItems(state.wishlist)
    return a.fetchGroupedIdRelationsRequest(items)
  },
})

addRule({
  id: 'user/wishlist/FETCH_GROUPED_ID_RELATIONS',
  target: at.FETCH_GROUPED_ID_RELATIONS_REQUEST,
  output: [at.FETCH_GROUPED_ID_RELATIONS_SUCCESS, at.FETCH_GROUPED_ID_RELATIONS_FAILURE],
  concurrency: 'SWITCH',
  consequence: (action) => {
    const { items } = action.meta
    return api.fetchGroupedIdRelations(items).then(
      (result) => a.fetchGroupedIdRelationsSuccess(items, result),
      (error) => a.fetchGroupedIdRelationsFailure(items, error.toString())
    )
  },
})

addRule({
  id: 'user/wishlist/ADD_GROUPED_ITEM',
  target: at.ADD_GROUPED_ITEM_REQUEST,
  output: [at.ADD_GROUPED_ITEM_SUCCESS, at.ADD_GROUPED_ITEM_FAILURE],
  condition: (_, { getState }) => {
    const state = getState()
    return !!s.getWishlistId(state.wishlist)
  },
  consequence: (action, { getState }) => {
    const { sku } = action.meta
    const state = getState()
    if (!state.wishlist) return
    const wishlistId = s.getWishlistId(state.wishlist)
    return api.addWishlistItem(wishlistId, sku, true).then(
      (item) => a.addWishlistGroupedItemSuccess(wishlistId, sku, item),
      (error) => a.addWishlistGroupedItemFailure(wishlistId, sku, error.toString())
    )
  },
})

addRule({
  id: 'user/wishlist/ADD_LISTING_ITEM',
  target: at.ADD_LISTING_ITEM_REQUEST,
  output: [at.ADD_LISTING_ITEM_SUCCESS, at.ADD_LISTING_ITEM_FAILURE],
  condition: (_, { getState }) => {
    const state = getState()
    if (!state.wishlist) return false
    return !!s.getWishlistId(state.wishlist)
  },
  consequence: (action, { getState }) => {
    const state = getState()
    const wishlistId = s.getWishlistId(state.wishlist)
    return api.addWishlistItem(wishlistId, action.meta.objectId, false).then(
      (item) => a.addWishlistListingItemSuccess(wishlistId, action.meta.objectId, item),
      (error) => a.addWishlistListingItemFailure(wishlistId, action.meta.objectId, error)
    )
  },
})

addRule({
  id: 'user/wishlist/REMOVE_ITEM',
  target: at.REMOVE_ITEM,
  output: '#remove-wishlist-item',
  concurrency: 'FIRST',
  concurrencyFilter: (action) => action.payload.itemId,
  consequence: (action) => api.removeWishlistItem(action.payload),
})

addRule({
  id: 'user/wishlist/FETCH_PRODUCTS',
  target: at.FETCH_PRODUCTS_REQUEST,
  output: [at.FETCH_PRODUCTS_SUCCESS, at.FETCH_PRODUCTS_FAILURE],
  concurrency: 'FIRST',
  condition: (_, { getState }) => {
    const state = getState()
    return !!s.getWishlistId(state.wishlist)
  },
  consequence: (_, { getState }) => {
    const state = getState()
    const wishlistId = s.getWishlistId(state.wishlist)
    return api.fetchWishlistProducts(wishlistId).then(
      (result) => a.fetchWishlistProductsSuccess(wishlistId, result),
      (error) => a.fetchWishlistProductsFailure(wishlistId, error)
    )
  },
})

addRule({
  id: 'user/wishlist/SUBSCRIPTION',
  target: at.SUBSCRIBE_REQUEST,
  output: [at.SUBSCRIBE_SUCCESS, at.SUBSCRIBE_FAILURE],
  concurrency: 'FIRST',
  consequence: (action) => {
    const { wishlistId, email } = action.meta
    return api.subscribeToWishlist(wishlistId, email).then(
      (result) => a.wishlistSubscribeSuccess(email, wishlistId, result),
      (error) => a.wishlistSubscribeFailure(email, wishlistId, error.toString())
    )
  },
})
addRule({
  id: 'user/wishlist/SAVE_REJECT_SUBSCRIPTION',
  target: at.REJECT_SUBSCRIPTION,
  output: '#localstorage',
  consequence: () => {
    localStorage.setItem('rlx_wishlist_rejected', 'true')
  },
})
