import * as React from 'react'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'
import { transformImgSrc, OptImg } from 'utils/imgOptimization'
import { useMediaSize } from 'modules/browser'

const loaded = {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let run = (cb: () => void) => null
try {
  // @ts-ignore
  run = window.requestIdleCallback || window.requestAnimationFrame
} catch (e) {} // eslint-disable-line no-empty

/**
 * Enables lazy loading of an image. When reference comes in viewport
 * the src will be fetched. Meanwhile a base64 image is shown.
 *
 * - base64 img is displayed while main img hasnot been fetched
 * - only fetches img when ref comes in viewport (with defined offset)
 * - if src is already loaded, no base64 img is dispayed
 * - waits with load until process idles
 *
 * @param src the image src
 * @param base64 the imageg src that is shown while main img is loading
 * @param offset the distance from viewport when the src will be loaded default = 100
 *
 *
 * @returns [react-ref, img-src]
 * @example
 * const [ref, src] = useLazyImageSrc('placeholder.com', '')
 * // ...
 * <img src={src} ref={ref} />
 */
export default function useLazyImageSrc(
  src: string | OptImg,
  base64 = '',
  offset = 200
): [any, string] {
  const [ref, inView]: any = useInView({
    triggerOnce: true,
    rootMargin: `${offset}px ${offset}px ${offset}px ${offset}px`,
  })
  const cacheKey = typeof src === 'string' ? src : src.src
  const { activeMs } = useMediaSize('>=', 'LAPTOP_XL')
  const [image, setImage] = React.useState<string>(loaded[cacheKey] || base64)

  React.useMemo(() => {
    if (!inView) return
    let url = typeof src !== 'string' ? transformImgSrc(src, activeMs) : src
    if (loaded[cacheKey]) {
      if (url !== image) setImage(url)
      else return
    }
    let canceled = false

    run(() => {
      const img = new Image()
      img.onload = () => {
        loaded[cacheKey] = url
        !canceled && setImage(url)
      }
      img.src = url
    })
    return () => {
      canceled = true
    }
  }, [inView, src, image, activeMs, cacheKey])

  return [ref, image]
}
