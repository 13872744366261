// @flow
import config from 'config'
import fetch from 'utils/universal-fetch'

export default async function graphql(query: string): Promise<any> {
  const response = await fetch(config.modules.appsync.apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-api-key': config.modules.appsync.apiKey,
    },
    body: JSON.stringify({ query }),
  })
  const { data } = await response.json()
  return data
}
