import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Output = {
  id: string | null
  set: typeof a.setSquarelovinId
}

const config: Config<any, Output, State, Record<string, unknown>> = {
  moduleKey: 'ui',
  name: 'ui/useSquarelovinId',
  createCacheKey: () => '',
  mapState: (state) => ({
    id: s.getSquarelovinId(state),
  }),
  mapDispatch: {
    set: a.setSquarelovinId,
  },
  areStatesEqual: (a, b) => a.squarelovinId === b.squarelovinId,
}

export default function useSquarelovinId(): Output {
  const hook: Output = useConnect({}, config)
  return hook
}
