// @flow
import * as t from '../types'
import * as a from '../actions'
import * as s from '../selectors'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  objectId: string
}

export type Output = {
  wishlistId: string
  item: t.WishlistItem | null
  selected: boolean
  isFetching: boolean
  add: () => void
  remove: () => void
}

type DP = {
  add: typeof a.addWishlistListingItemRequest
  remove: typeof a.removeWishlistItem
}

const config: Config<Input, Output, State, DP> = {
  moduleKey: 'wishlist',
  name: 'wishlist/useWishlistListingItem',
  createCacheKey: (props) => props.objectId,
  mapState: (state, props) => {
    const item = s.getListingItem(state, props.objectId)
    return {
      wishlistId: s.getWishlistId(state),
      item,
      selected: !!item,
      isFetching: s.isFetchingItems(state),
    }
  },
  mapDispatch: {
    add: a.addWishlistListingItemRequest,
    remove: a.removeWishlistItem,
  },
  transformDispatch: {
    add: (fn, sp, props) => () => !sp.selected && fn(sp.wishlistId, props.objectId),
    remove: (fn, sp) => () => sp.item && fn(sp.item),
  },
  areStatesEqual: (a, b) => a.items === b.items && a.wishlistId === b.wishlistId,
}

export default function useWishlistListingItem(props: Input): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
