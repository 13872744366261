import * as t from './types'
import * as at from './const'

export const locationChange = (prevLocation: t.Location, nextLocation: t.Location) => ({
  type: at.LOCATION_CHANGE,
  meta: { prevLocation },
  payload: nextLocation,
})

export type LocationChange = ReturnType<typeof locationChange>

export type Action = LocationChange
