// @flow
import * as React from 'react'

const portals = new Set()
// eslint-disable-next-line @typescript-eslint/ban-types
let changeListener: Function = () => null

const removeComponent = (Component: any) => {
  if (!portals.has(Component)) return
  portals.delete(Component)
  changeListener()
}

export const addComponent = (Component: any) => {
  if (portals.has(Component)) return
  portals.add(Component)
  changeListener()
  return () => removeComponent(Component)
}

export default function PortalProvider() {
  const [elements, setElements] = React.useState<any>([])

  React.useEffect(() => {
    changeListener = () => setElements(Array.from(portals))
    return () => {
      changeListener = () => null
    }
  }, [])

  return (
    <div className="PortalProvider">
      {/* <div style={{position:'absolute', zIndex:999999999, left:0, right:0, top:0, bottom:0 ,background:'green'}}/> */}
      {elements.map((El: any, i) => {
        // console.log(El)
        return <El key={i} close={() => removeComponent(El)} />
        // return <div key={i} style={{position:'fixed', zIndex:999999999, left:0, right:0, top:0, bottom:0 ,background:'green'}}>
        //   <El close={() => removeComponent(El)}/>
        // </div>
        // const C = () =>  <El close={() => removeComponent(El)}/>
        // return <C key={i}/>
      })}
    </div>
  )
}
