import * as React from 'react'
import LinkList from '../LinkList'
import useCategories from './hooks/useCategories'

type Props = {
  onClose: () => void
  visible: boolean
}

export default function FashionDropdown(props: Props) {
  const [activeItem, subItems, handleItemClick, onBackClick] = useCategories(props.onClose)

  return (
    <div style={{ display: props.visible ? 'block' : 'none' }}>
      <LinkList
        title={activeItem ? activeItem.label : 'Shop'}
        onBackClick={onBackClick}
        onItemClick={handleItemClick}
        items={subItems}
      />
    </div>
  )
}
