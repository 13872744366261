import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  open: boolean
  setCartOpen: typeof a.setCartOpen
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'ui',
  name: 'ui/useCartStatus',
  createCacheKey: () => '',
  mapState: (state) => ({
    open: s.isCartOpen(state),
  }),
  mapDispatch: {
    setCartOpen: a.setCartOpen,
  },
  areStatesEqual: (a, b) => a.cartOpen === b.cartOpen,
}

export default function useCartStatus(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
