// @flow
import 'intersection-observer'
import * as React from 'react'
import styled from 'styled-components'
import Header from 'theme/_core/Header'
import Sidebar from 'theme/_core/Sidebar'
import Footer from 'theme/_core/Footer'
// import ScrollToTopButton from 'theme/_core/ScrollToTopButton'
import GlobalStyle from './GlobalStyle'
import PortalProvider from './PortalProvider'
import SquarelovinPopup from 'theme/_core/SquarelovinPopup'
import ErrorBoundary from './ErrorBoundary'
import config from 'config'
import 'utils/notifyInternetExplorer'

type Props = {
  children: any
}

export default function Layout({ children }: Props) {
  return (
    <ErrorBoundary>
      <GlobalStyle />
      <Header />
      <Content>
        <Sidebar />
        <div className="inner">
          {children}
          {config.features.cookiebot && (
            // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
            <script
              id="CookieDeclaration"
              src="https://consent.cookiebot.com/5ed0b2c1-553d-4db0-a804-b302a21772a7/cd.js"
              type="text/javascript"
              async
            ></script>
          )}
        </div>
      </Content>
      <Footer />
      {/* <ScrollToTopButton/> */}
      <PortalProvider />
      <SquarelovinPopup />
    </ErrorBoundary>
  )
}

const Content = styled.div`
  display: flex;
  > .inner {
    flex: 1;
  }
`
