import * as React from 'react'
import { useSquarelovinId } from 'modules/ui'
import * as t from './types'
import { fetchEntry } from 'utils/squarelovin'
import loadable from '@loadable/component'

const Popup = loadable(() => import(/* webpackChunkName: "squarelovin-popup" */ './Popup'))

export default function SquarelovinPopup() {
  const sq = useSquarelovinId()
  const [entry, setEntry] = React.useState<null | t.Entry>(null)

  React.useEffect(() => {
    if (!sq.id) return
    Popup.preload()
    fetchEntry(sq.id).then(setEntry, () => null)
  }, [sq.id])

  React.useEffect(() => {
    if (entry && !sq.id) setEntry(null)
  }, [entry, sq.id])

  if (!entry || !sq.id) return null

  return <Popup entry={entry} onClose={() => sq.set(null)} />
}
