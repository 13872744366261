import * as s from '../selectors'
import * as a from '../actions'
import * as t from '../types'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  open: boolean
  component: t.SidebarComponent
  setOpen: typeof a.setSidebarOpen
  setComponent: typeof a.setSidebarComponent
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'ui',
  name: 'ui/useSidebarStatus',
  createCacheKey: () => '',
  mapState: (state) => ({
    open: s.isSidebarOpen(state),
    component: s.getSidebarComponent(state),
  }),
  mapDispatch: {
    setOpen: a.setSidebarOpen,
    setComponent: a.setSidebarComponent,
  },
}

export default function useSidebarStatus(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
