import * as React from 'react'
import styled from 'styled-components'
import { useSidebarStatus } from 'modules/ui'
import { FiX } from 'react-icons/fi'
import posed, { PoseGroup } from 'react-pose'
import { ms } from 'modules/browser/const'
import loadable from '@loadable/component'

import RootNavigation from './RootNavigation'
import FashionDropdown from './FashionDropdown'

const InspirationDropdown = loadable(() =>
  import(/* webpackChunkName: "inspiration-dropdown" */ './InspirationDropdown')
)
const GuidanceDropdown = loadable(() =>
  import(/* webpackChunkName: "guidance-dropdown" */ './GuidanceDropdown')
)

export default function Sidebar() {
  const sidebar = useSidebarStatus()

  const closeSidebar = () => {
    sidebar.open && sidebar.setOpen(false)
    sidebar.component && sidebar.setComponent(null)
  }

  React.useEffect(() => {
    if (!sidebar.open) return
    InspirationDropdown.preload()
    GuidanceDropdown.preload()
  }, [sidebar.open])

  return (
    <PoseGroup>
      {sidebar.open && (
        <Overlay
          key="overlay"
          className="Sidebar-Overlay"
          onClick={closeSidebar}
          data-cy-ctx="core/Sidebar"
          data-cy-state="modal-open"
        >
          <FiX />
        </Overlay>
      )}
      <Wrapper
        key="wrapper"
        className="Sidebar"
        open={sidebar.open}
        data-cy-ctx="core/Sidebar"
        data-cy-state="modal-open"
      >
        {!sidebar.component && (
          <RootNavigation setComponent={sidebar.setComponent} data-cy-state="has-sidebar" />
        )}
        <FashionDropdown
          onClose={() => sidebar.setComponent(null)}
          visible={sidebar.component === 'fashion'}
        />
        {sidebar.component === 'inspiration' && (
          <InspirationDropdown
            onClose={() => sidebar.setComponent(null)}
            data-cy-state="has-inspiration"
          />
        )}
        {sidebar.component === 'guidance' && (
          <GuidanceDropdown
            onClose={() => sidebar.setComponent(null)}
            data-cy-state="has-guidance"
          />
        )}
      </Wrapper>
    </PoseGroup>
  )
}

const OverlayA = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})
const Overlay = styled(OverlayA)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999998;
  background: rgba(153, 51, 80, 0.8);
  padding: 10px;
  padding-left: 250px;
  color: white;
  font-size: 30px;
  cursor: pointer;

  @media (min-width: ${ms.LAPTOP}px) {
    padding-top: 90px;
  }
`

const Wrapper = styled.div`
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
  position: fixed;
  z-index: 9999999;
  background: #f8f7f8;
  overflow-y: auto;
  display: ${(props) => (props.open ? 'block' : 'none')};

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${ms.LAPTOP}px) {
    margin-top: 00px;
  }
`
