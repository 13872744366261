import * as at from './const'
import * as t from './types'

export const setMediaSize = (mediaSize: t.MediaSize) => ({
  type: at.SET_MEDIA_SIZE,
  payload: mediaSize,
})

export type SetMediaSize = ReturnType<typeof setMediaSize>

export type Action = SetMediaSize
