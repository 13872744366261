// @flow
import { State } from './reducer'
import * as t from './types'

export const isCartOpen = (state: State): boolean => state.cartOpen

export const isSidebarOpen = (state: State): boolean => state.sidebarOpen

export const getProductModalId = (state: State): string | null => state.productModalId

export const getSquarelovinId = (state: State): string | null => state.squarelovinId

export const getSidebarComponent = (state: State): t.SidebarComponent => state.sidebarComponent
