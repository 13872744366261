import * as React from 'react'

type Props = {
  fill: string
}

export default function WucuLogo(props: Props) {
  return (
    <svg
      className="WucuLogo"
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 738 135"
      {...props}
    >
      <g>
        <path
          className="st0"
          d="M220.7,46.2c-0.9,0-1.7,0.3-2.3,0.9c-0.4,0.4-0.6,0.8-0.8,1.2l-13.2,47.5l-13.2-47.5c-0.2-0.4-0.4-0.8-0.8-1.2
        c-0.7-0.6-1.4-0.9-2.3-0.9c-0.9,0-1.7,0.3-2.3,0.9c-0.4,0.4-0.7,0.8-0.8,1.2l-13.2,47.5l-13.2-47.5c-0.2-0.4-0.4-0.8-0.8-1.2
        c-0.7-0.6-1.4-0.9-2.3-0.9c-0.9,0-1.7,0.3-2.3,0.9c-0.6,0.6-1,1.4-1,2.3c0,0.5,0.1,0.9,0.2,1.4l16,57.7c0.2,0.6,0.5,1.2,1,1.7
        c0.6,0.7,1.4,1,2.3,1c0.9,0,1.7-0.3,2.3-1c0.5-0.5,0.8-1.1,1-1.7l12.9-46.8l13,46.8c0.2,0.6,0.5,1.2,1,1.7c0.6,0.7,1.4,1,2.3,1
        c0.9,0,1.7-0.3,2.3-1c0.5-0.5,0.8-1.1,1-1.7l15.9-57.7c0.2-0.5,0.2-1,0.2-1.4c0-0.9-0.3-1.7-1-2.3
        C222.4,46.5,221.6,46.2,220.7,46.2"
        />
        <path
          className="st0"
          d="M265.1,65.7c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.4-1,2.3v21.1c0,4-1.4,7.5-4.3,10.4c-2.9,2.9-6.3,4.3-10.4,4.3
        c-4,0-7.5-1.4-10.4-4.3c-2.9-2.9-4.3-6.3-4.3-10.4V69c0-0.9-0.3-1.6-1-2.3c-0.6-0.6-1.4-1-2.3-1c-0.9,0-1.7,0.3-2.3,1
        c-0.6,0.6-1,1.4-1,2.3v21.1c0,5.8,2.1,10.8,6.2,14.9c4.1,4.1,9.1,6.2,14.9,6.2c5.7,0,10.6-2,14.6-5.9v2.6c0,0.9,0.3,1.7,1,2.3
        c0.6,0.6,1.4,1,2.3,1c0.9,0,1.7-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3V69c0-0.9-0.3-1.6-1-2.3C266.7,66,265.9,65.7,265.1,65.7"
        />
        <path
          className="st0"
          d="M298.5,65.7c-5.7,0-10.6,2-14.6,5.9V69c0-0.9-0.3-1.6-1-2.3c-0.6-0.6-1.4-1-2.3-1c-0.9,0-1.7,0.3-2.3,1
        c-0.6,0.6-1,1.4-1,2.3v39c0,0.9,0.3,1.6,1,2.3c0.6,0.6,1.4,1,2.3,1c0.9,0,1.7-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3V86.8
        c0-4,1.4-7.5,4.3-10.3c2.9-2.9,6.3-4.3,10.3-4.3c4,0,7.5,1.4,10.4,4.3c2.9,2.9,4.3,6.3,4.3,10.3V108c0,0.9,0.3,1.6,1,2.3
        c0.6,0.6,1.4,1,2.3,1c0.9,0,1.6-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3V86.8c0-5.8-2.1-10.8-6.2-14.9C309.3,67.8,304.3,65.7,298.5,65.7"
        />
        <path
          className="st0"
          d="M368.4,46.1c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.4-1,2.3v23.2c-0.1-0.1-0.1-0.1-0.2-0.2
        c-4.4-4.4-9.8-6.7-16.1-6.7c-6.3,0-11.7,2.2-16.1,6.7c-4.5,4.5-6.7,9.8-6.7,16.1c0,6.3,2.2,11.7,6.7,16.1c4.4,4.4,9.8,6.7,16.1,6.7
        c6.3,0,11.7-2.2,16.1-6.7c4.5-4.4,6.7-9.8,6.7-16.1V49.4c0-0.9-0.3-1.7-1-2.3C370.1,46.4,369.3,46.1,368.4,46.1 M360.4,100
        c-3.2,3.2-7,4.8-11.5,4.8c-4.5,0-8.3-1.6-11.5-4.8c-3.2-3.2-4.8-7-4.8-11.5c0-4.5,1.6-8.3,4.8-11.5c3.2-3.2,7-4.8,11.5-4.8
        c4.5,0,8.3,1.6,11.5,4.8c3.2,3.2,4.8,7,4.8,11.5C365.2,93,363.6,96.8,360.4,100"
        />
        <path
          className="st0"
          d="M400.9,65.7c-6.3,0-11.7,2.2-16.1,6.7c-4.4,4.5-6.7,9.8-6.7,16.1c0,6.3,2.2,11.7,6.7,16.1
        c4.4,4.4,9.8,6.7,16.1,6.7c5.7,0,10.6-1.8,14.8-5.5c0.6-0.6,0.9-1.4,0.9-2.3c0-0.9-0.3-1.6-1-2.3c-0.6-0.6-1.4-1-2.3-1
        c-0.7,0-1.4,0.2-2,0.7c-2.9,2.6-6.4,3.9-10.5,3.9c-4.5,0-8.3-1.6-11.5-4.8c-2.4-2.4-3.9-5.1-4.5-8.2h35.5c0.9,0,1.7-0.3,2.3-1
        c0.6-0.6,1-1.4,1-2.3c0-6.3-2.2-11.7-6.7-16.1C412.6,67.9,407.2,65.7,400.9,65.7 M384.9,85.2c0.6-3.1,2.1-5.9,4.5-8.2
        c3.2-3.2,7-4.8,11.5-4.8c4.5,0,8.3,1.6,11.5,4.8c2.4,2.4,3.9,5.1,4.5,8.2H384.9z"
        />
        <path
          className="st0"
          d="M463,68.2c-0.4-0.4-0.8-0.7-1.3-0.9c-2.7-1.1-5.6-1.6-8.7-1.6c-6.3,0-11.7,2.3-16.3,6.8V69
        c0-0.9-0.3-1.6-1-2.3c-0.6-0.6-1.4-1-2.3-1c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.4-1,2.3v39c0,0.9,0.3,1.6,1,2.3c0.6,0.6,1.4,1,2.3,1
        c0.9,0,1.7-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3V88.5c0-4.5,1.6-8.3,4.8-11.5c3.2-3.2,7-4.8,11.5-4.8c2.3,0,4.4,0.4,6.3,1.2
        c0.4,0.2,0.9,0.3,1.4,0.3c0.9,0,1.7-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3C464,69.6,463.7,68.8,463,68.2"
        />
        <path
          className="st0"
          d="M486.3,72.2c4.1,0,7.6,1.3,10.6,4c0.6,0.5,1.3,0.7,2.1,0.7c0.9,0,1.6-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3
        c0-1-0.4-1.8-1.1-2.5c-4.2-3.7-9.1-5.5-14.8-5.5c-6.3,0-11.7,2.2-16.1,6.7c-4.5,4.5-6.7,9.8-6.7,16.1c0,6.3,2.2,11.7,6.7,16.1
        c4.4,4.4,9.8,6.7,16.1,6.7c5.7,0,10.6-1.8,14.8-5.5c0.7-0.7,1-1.5,1-2.4c0-0.9-0.3-1.6-0.9-2.3c-0.6-0.6-1.4-1-2.3-1
        c-0.8,0-1.4,0.2-2,0.7c-3,2.6-6.6,4-10.6,4c-4.5,0-8.3-1.6-11.5-4.8c-3.2-3.2-4.8-7-4.8-11.5c0-4.5,1.6-8.3,4.8-11.5
        C477.9,73.8,481.8,72.2,486.3,72.2"
        />
        <path
          className="st0"
          d="M548.1,65.7c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.4-1,2.3v21.1c0,4-1.4,7.5-4.3,10.4c-2.9,2.9-6.3,4.3-10.4,4.3
        c-4,0-7.5-1.4-10.4-4.3c-2.9-2.9-4.3-6.3-4.3-10.4V69c0-0.9-0.3-1.6-1-2.3c-0.6-0.6-1.4-1-2.3-1s-1.7,0.3-2.3,1
        c-0.6,0.6-1,1.4-1,2.3v21.1c0,5.8,2.1,10.8,6.2,14.9c4.1,4.1,9.1,6.2,15,6.2c5.7,0,10.6-2,14.6-5.9v2.6c0,0.9,0.3,1.7,1,2.3
        c0.6,0.6,1.4,1,2.3,1c0.9,0,1.6-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3V69c0-0.9-0.3-1.6-1-2.3C549.7,66,549,65.7,548.1,65.7"
        />
        <path
          className="st0"
          d="M592.5,67.3c-2.7-1.1-5.6-1.6-8.7-1.6c-6.3,0-11.7,2.3-16.3,6.8V69c0-0.9-0.3-1.6-1-2.3c-0.6-0.6-1.4-1-2.3-1
        c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.4-1,2.3v39c0,0.9,0.3,1.6,1,2.3c0.6,0.6,1.4,1,2.3,1c0.9,0,1.6-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3
        V88.5c0-4.5,1.6-8.3,4.8-11.5c3.2-3.2,7-4.8,11.5-4.8c2.3,0,4.4,0.4,6.3,1.2c0.4,0.2,0.9,0.3,1.4,0.3c0.9,0,1.7-0.3,2.3-1
        c0.6-0.6,1-1.4,1-2.3c0-0.9-0.3-1.7-0.9-2.3C593.4,67.8,593,67.5,592.5,67.3"
        />
        <path
          className="st0"
          d="M636.5,65.7c-0.9,0-1.6,0.3-2.3,0.9c-0.2,0.2-0.5,0.5-0.6,0.9l-15,32.7l-15-32.7c-0.2-0.3-0.4-0.6-0.6-0.9
        c-0.7-0.6-1.4-0.9-2.3-0.9c-0.9,0-1.7,0.3-2.3,1c-0.7,0.6-1,1.4-1,2.3c0,0.5,0.1,0.9,0.3,1.3l18.1,39.3c0.2,0.3,0.3,0.6,0.5,0.8
        c0.6,0.6,1.4,0.9,2.3,0.9c0.9,0,1.7-0.3,2.3-0.9c0.2-0.2,0.4-0.5,0.5-0.8l18-39.3c0.2-0.4,0.2-0.8,0.2-1.3c0-0.9-0.3-1.6-1-2.3
        C638.2,66,637.4,65.7,636.5,65.7"
        />
        <path
          className="st0"
          d="M665.2,65.7c-6.3,0-11.7,2.2-16.1,6.7c-4.4,4.5-6.7,9.8-6.7,16.1c0,6.3,2.2,11.7,6.7,16.1
        c4.4,4.4,9.8,6.7,16.1,6.7c5.7,0,10.6-1.8,14.8-5.5c0.6-0.6,0.9-1.4,0.9-2.3c0-0.9-0.3-1.6-1-2.3c-0.6-0.6-1.4-1-2.3-1
        c-0.7,0-1.4,0.2-2,0.7c-2.9,2.6-6.4,3.9-10.5,3.9c-4.5,0-8.3-1.6-11.5-4.8c-2.4-2.4-3.9-5.1-4.5-8.2h35.5c0.9,0,1.7-0.3,2.3-1
        c0.6-0.6,1-1.4,1-2.3c0-6.3-2.2-11.7-6.7-16.1C676.9,67.9,671.5,65.7,665.2,65.7 M649.3,85.2c0.6-3.1,2.1-5.9,4.5-8.2
        c3.2-3.2,7-4.8,11.5-4.8c4.5,0,8.3,1.6,11.5,4.8c2.4,2.4,3.9,5.1,4.5,8.2H649.3z"
        />
        <path
          className="st0"
          d="M726.3,91.4c-1-1-2.7-2-5.2-2.9l-20-6.6c-1.3-0.5-1.9-1.3-2-2.6c0-1.1,0.3-2,1-2.8c2.7-2.8,6.3-4.2,10.8-4.2
        c4.5,0,8.3,1.6,11.5,4.8c0.2,0.2,0.6,0.6,1.2,1.2c0.5,0.6,1.3,0.9,2.1,0.9c0.9,0,1.7-0.3,2.3-1c0.6-0.6,0.9-1.4,0.9-2.3
        c0-0.8-0.2-1.4-0.6-1.9c-4.9-5.5-10.7-8.2-17.5-8.2c-6.3,0-11.4,2-15.3,6c-2,2-3,4.3-3,7c0,2.7,1,5,2.9,6.9c1,1,2.7,2,5.2,2.9
        l20,6.6c1.2,0.5,1.9,1.3,2,2.6c0,1.1-0.3,2-1,2.8c-2.7,2.8-6.3,4.2-10.8,4.2c-4.5,0-8.3-1.6-11.5-4.8c-0.3-0.2-0.6-0.7-1.2-1.2
        c-0.5-0.6-1.3-0.9-2.2-0.9c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.4-1,2.3c0,0.7,0.2,1.3,0.6,1.9c4.8,5.5,10.7,8.2,17.5,8.2
        c6.3,0,11.4-2,15.3-6c2-2,3-4.3,3-7C729.2,95.5,728.2,93.3,726.3,91.4"
        />
        <g>
          <g className="st1">
            <path
              className="st0"
              d="M75.3,126.1c0.5-3.4-2.9-6.4-1.8-9.7c1-1.9-0.9-3.4-2.5-4.5c-0.9-2-0.6-4.4-1.2-6.6
            c-0.8-1.7-0.1-3.4,0.3-5.1c0.5-4.2-0.6-8.4-0.1-12.6c1.3-4.7,3.5-9.3,5.1-14.1c0.1-0.4,0.2-0.7,0.4-1.1c0.2-0.5,0.3-0.9,0.5-1.3
            c1.1-3,2-5.1,1.5-8.8c-0.5-4.6-1.9-6.2-3.7-9.3c-1.2-2.1-3.4-6.8-2.8-10.3c0,0,0.1,0,0.1,0c0.5,1.3,5.3,5,7.7,7.9
            c2.2,4,5.7,7.3,9.2,10.5c0.6,0.3,1.2,0.5,1.9,0.6c0.6,0,1.2,0.1,1.7,0.2c0.1,0,0.2,0,0.3,0.1c0.9,0.3,1.7,0.9,2.4,1.6
            c3.2,0.4-2.5-3.1-0.5-3c1.9-0.1,3.5,1.2,5.6,0.8c4.1,0.2-0.1-1.4-1.9-1.2c-1.5-1-3.3-1.2-5.2-1.3c-0.1,0-0.2,0-0.3,0
            c-0.6,0-1.3,0-1.9,0c-3.1-3.4-6-7.9-7.6-11.9c-4.2-3.7-4.4-8.3-6.1-13c-0.9-1.6-1.8-4-4-4.8c0,0,0,0,0-0.1
            c1.7-3,2.7-9.5-3.9-19.2c-1.7-2.6-4.1-5.5-9.7-3.6C53.2,5.9,52,15.9,54,19.2c-0.8,2.1,2.1,2.2,1.8,3.5c0.5,1.5,1.8,3,1.2,4.6
            c-0.9,2.4-3.4,1.9-5.8,2c-2.8-0.3-4.7,3.3-4.6,5c-0.3,4.9,0.4,9.6-1.8,14.2c-2.4,3.1-3.8,6.7-6.5,9.7c-1.6,1.5-2.7,4.4-5.6,3.8
            c0,0,0,0-0.1,0l0,0c-2.7-0.4-4.8,1.1-7.3,1.5c-2.8-0.1-2.6,1.4,0,1c2.1,0.4,3.8-1.4,5.8-0.7c-1.1,0.4-3.5,4.2-0.5,2.5
            c0.5-1,1.4-1.2,2.4-1.3l0,0c0.8-0.1,1.7-0.1,2.5-0.4c4.6-2.7,7.9-6.7,11.5-10.3c2.4-1.7,2.9-4.4,4-6.7c1.2,3.2-2.5,7.3-2.7,10.5
            c-0.6,2.7-0.6,4.1-0.5,6.9c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.3,0.8,2.6,1.3,4c1.9,5,4.8,10.1,6.9,14.6c1.9,2.7,0.9,5.8,1.7,8.6
            c0.7,2.5,2.2,4.8,3,7.2c-0.1,4.7,0,9.5-0.8,14.2c-1.1,1.9-1.7,3.9-2.3,5.9c-0.5,2.4-2.5,4.6-3.4,6.9c-0.2,0.2-0.3,0.5-0.3,0.7
            c0,0,0,0,0,0c-0.2,1.2,1.6,1.8,2.8,1.7c1.4,0,2.6-1.2,4.1-1c0.5-0.2,1-0.4,1.3-0.7c0,0,0,0,0,0c1.4-1.2,1.2-3.2,1.2-4.9
            c0.9-2.9,0-6,1.3-8.8c0.1-0.2,0.2-0.4,0.3-0.6c0.8-2.5,1.3-1.7,1.7,0.4c1.7,2.4,1.4,5.1,1.4,7.7c0.3,1.8-0.7,4,0.7,5.5
            c0.2,0.3,0.6,0.5,0.9,0.7c0,0,0,0,0,0c1,0.4,2.2,0.4,3.3,0c0,0,0,0,0,0C74.6,128.2,75.6,127.3,75.3,126.1z"
            />
          </g>
          <path
            className="st0"
            d="M52.5,126.6l-0.3,0.6c0.4-1.2,1.1-2.3,1.7-3.3c0.7-1.1,1.3-2.2,1.5-3.1l0-0.1l0-0.1c0.6-1.9,1.3-3.9,2.3-5.9
          c0.6-3.8,0.7-7.8,0.7-11.8c0,0,0-0.7,0-1.2c-0.3-0.9-0.7-1.7-1.2-2.6c-0.7-1.4-1.3-2.8-1.8-4.4c-0.4-1.4-0.4-2.7-0.5-3.9
          c-0.1-1.8-0.1-2.9-0.8-3.9l-0.1-0.2l-2.6-5.3c-1.6-3.1-3.2-6.3-4.4-9.5L39,111.1l-5.6-43.7c0,0,0,0,0,0c-0.3,0-0.4,0.1-0.5,0.1
          c-0.1,0.2-0.3,0.6-0.3,0.6l-0.7,0.4c-0.6,0.4-2.3,1.3-3.9,0.3c-0.6-0.4-1-1.1-1.1-1.8c-0.5,0.1-0.9,0.1-1.5,0
          c-2.3,0.3-3.4-0.5-4-1.2c-0.4-0.5-0.5-1-0.5-1.6c0-0.4,0.1-0.9,0.3-1.3c0.4-0.8,1.5-1.8,3.9-1.8c0.7-0.1,1.5-0.4,2.3-0.6
          c1.5-0.5,3.1-1,5-0.9l-5.8-44.8H7.2l11.7,102.7c0,0,1.2,11.3,9.4,11.3l23.5,0c0-0.1,0-0.3,0-0.4L52.5,126.6z"
          />
          <path
            className="st0"
            d="M97.9,14.7l-5.6,41.9c0,0,0,0,0,0c1.6,0,3.7,0.3,5.7,1.3c1.6,0.1,4.2,0.6,5.1,2c0.3,0.4,0.4,0.9,0.4,1.4
          c0,0.4-0.1,0.9-0.3,1.3c-0.9,1.5-2.7,1.5-3.9,1.5c-0.6,0.1-1.2,0-1.7,0c0,0.2,0,0.4-0.2,0.7c-0.4,0.8-1.4,1.8-3.5,1.5L93.1,66
          l-0.6-0.6c-0.5-0.5-0.9-0.8-1.3-0.9l-6.6,49.3L76.9,76c-0.6,1.6-1.2,3.1-1.8,4.6c-1,2.6-2,5-2.6,7.5c-0.1,0.6-0.1,1.3-0.1,2
          c0,1.2,0.1,2.4,0.2,3.6c0.1,1.3,0.2,2.6,0.2,4c0,0.9,0,1.9-0.2,2.9l0,0.1l-0.3,1.2c-0.2,0.8-0.3,1.4-0.3,1.8c0,0.2,0,0.4,0.1,0.6
          l0.1,0.2l0,0.2c0.3,1.2,0.5,2.4,0.6,3.4c0.1,0.9,0.2,1.7,0.4,2.4c1.3,1,3.1,2.7,3.1,4.9c0,0.6-0.1,1.2-0.5,1.9
          c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.7,0.3,1.6,0.8,2.8c0.6,1.4,1.2,3,1.2,4.8c0,0.2,0,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4
          c0,0.7-0.2,1.5-0.6,2.1l19,0c8.2,0,9.4-11.3,9.4-11.3l11.7-102.7H97.9z"
          />
        </g>
      </g>
    </svg>
  )
}
