// @flow
import { dispatchEvent } from 'redux-ruleset'
import * as t from './types'
import * as a from './actions'

/**
Gatsby provides hooks for listening to location changes in gatsby-browser
since gatsby browser has no access to the redux store, we provide a global function
that it can call. this function will execute the event
 */

declare global {
  interface Window {
    ___triggerGatsbyLocationChange: any
  }
}

if (typeof window !== 'undefined') {
  window.___triggerGatsbyLocationChange = (prevLocation: t.Location, nextLocation: t.Location) => {
    dispatchEvent(a.locationChange(prevLocation, nextLocation))
  }
}
