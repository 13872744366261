// @flow
import './rules'

import store from 'store'
import reducer, { State } from './reducer'
import { Action } from './actions'

store.injectReducer(store, 'user', reducer)

declare global {
  interface RootState {
    user: State
  }
  interface RootReducers {
    user: typeof reducer
  }
  interface ModuleActions {
    user: Action
  }
}

export * from './const'
export * from './selectors'
export * from './actions'
export * from './types'

export { default as useRedirect } from './hooks/useRedirect'
export { default as useUser } from './hooks/useUser'
