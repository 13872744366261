// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-theme-templates-500-tsx": () => import("./../../../src/theme/templates/500.tsx" /* webpackChunkName: "component---src-theme-templates-500-tsx" */),
  "component---src-theme-templates-auth-0-callback-tsx": () => import("./../../../src/theme/templates/Auth0Callback.tsx" /* webpackChunkName: "component---src-theme-templates-auth-0-callback-tsx" */),
  "component---src-theme-templates-author-tsx": () => import("./../../../src/theme/templates/Author.tsx" /* webpackChunkName: "component---src-theme-templates-author-tsx" */),
  "component---src-theme-templates-brand-index-brand-index-tsx": () => import("./../../../src/theme/templates/BrandIndex/BrandIndex.tsx" /* webpackChunkName: "component---src-theme-templates-brand-index-brand-index-tsx" */),
  "component---src-theme-templates-category-tsx": () => import("./../../../src/theme/templates/Category.tsx" /* webpackChunkName: "component---src-theme-templates-category-tsx" */),
  "component---src-theme-templates-homepage-tsx": () => import("./../../../src/theme/templates/Homepage.tsx" /* webpackChunkName: "component---src-theme-templates-homepage-tsx" */),
  "component---src-theme-templates-magazine-article-tsx": () => import("./../../../src/theme/templates/MagazineArticle.tsx" /* webpackChunkName: "component---src-theme-templates-magazine-article-tsx" */),
  "component---src-theme-templates-magazine-list-tsx": () => import("./../../../src/theme/templates/MagazineList.tsx" /* webpackChunkName: "component---src-theme-templates-magazine-list-tsx" */),
  "component---src-theme-templates-page-tsx": () => import("./../../../src/theme/templates/Page.tsx" /* webpackChunkName: "component---src-theme-templates-page-tsx" */),
  "component---src-theme-templates-preview-tsx": () => import("./../../../src/theme/templates/Preview.tsx" /* webpackChunkName: "component---src-theme-templates-preview-tsx" */),
  "component---src-theme-templates-sale-tsx": () => import("./../../../src/theme/templates/Sale.tsx" /* webpackChunkName: "component---src-theme-templates-sale-tsx" */),
  "component---src-theme-templates-search-tsx": () => import("./../../../src/theme/templates/Search.tsx" /* webpackChunkName: "component---src-theme-templates-search-tsx" */),
  "component---src-theme-templates-story-only-page-tsx": () => import("./../../../src/theme/templates/StoryOnlyPage.tsx" /* webpackChunkName: "component---src-theme-templates-story-only-page-tsx" */)
}

