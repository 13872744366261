import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'

type Props = {
  icon: any
  to?: string
  onClick?: () => void
  numItems?: number
  disabled?: boolean
  'data-cy-state'?: string
  'data-cy-handle'?: string
  'data-cy-state-hasNumItems'?: string
}

export default function Icon(props: Props) {
  return (
    <Wrapper
      data-cy-state={props['data-cy-state']}
      className="Icon"
      data-cy-handle={props['data-cy-handle']}
      onClick={props.onClick}
      to={props.to}
      disabled={props.disabled}
    >
      <props.icon />
      {Boolean(props.numItems) && (
        <span data-cy-state={props['data-cy-state-hasNumItems']}>{props.numItems}</span>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  ${props => props.disabled ? `cursor:not-allowed;` : 'cursor:pointer;'}
  height: 28px;
  position: relative;
  /* cursor: pointer; */
  > svg {
    color: white;
    font-size: 28px;
  }

  > span {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans';
    font-size: 10.8px;
    font-weight: bold;
    letter-spacing: 0.45px;
    line-height: 15px;
    color: #993452;
  }
`
