import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = Record<string, unknown>

export type Output = {
  objectId: string | null
  openProductModal: typeof a.openProductModal
  closeProductModal: () => typeof a.closeProductModal
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'ui',
  name: 'ui/useProductModalId',
  createCacheKey: () => '',
  mapState: (state) => ({
    objectId: s.getProductModalId(state),
  }),
  mapDispatch: {
    openProductModal: a.openProductModal,
    closeProductModal: a.closeProductModal,
  },
}

export default function useProductModalId(props: Input = {}): Output {
  const hook: Output = useConnect(props, config)
  return hook
}
