import * as at from './const'
import * as t from './types'
import { Action } from './actions'

export type State = {
  wishlistId: string
  data: t.Wishlist
  items: {
    isFetching: boolean
    fetchError: string | null
    data: t.WishlistItem[]
    pending: string[]
  }
  groupedIdRelations: { [sku: string]: string }
  products: {
    isFetching: boolean
    fetchError: string | null
    data: t.WishlistProduct[]
  }
}

export const defaultState: State = {
  wishlistId: '',
  data: {
    hasSubscription: false,
    isSubscribing: false,
    rejectedSubscription: (() => {
      if (typeof window === 'undefined') return false
      try {
        return localStorage.getItem('rlx_wishlist_rejected') === 'true'
      } catch (e) {
        return false
      }
    })(),
    pendingSubscription: false,
  },
  groupedIdRelations: {},
  items: {
    isFetching: false,
    fetchError: null,
    data: [],
    pending: [],
  },
  products: {
    isFetching: false,
    fetchError: null,
    data: [],
  },
}

export default function reducer(state = defaultState, action: Action): State {
  switch (action.type) {
    case at.SET_WISHLIST_ID:
      return { ...state, wishlistId: action.payload }
    case at.ADD_LISTING_ITEM_REQUEST:
      return {
        ...state,
        items: {
          ...state.items,
          pending: [...state.items.pending, action.meta.objectId],
        },
      }
    case at.ADD_GROUPED_ITEM_REQUEST:
      return {
        ...state,
        items: {
          ...state.items,
          pending: [...state.items.pending, action.meta.sku],
        },
      }
    case at.ADD_LISTING_ITEM_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          data: [...state.items.data, action.payload],
          pending: state.items.pending.filter((row) => row === action.meta.objectId),
        },
      }
    case at.ADD_GROUPED_ITEM_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          data: [...state.items.data, action.payload],
          pending: state.items.pending.filter((row) => row === action.meta.sku),
        },
      }
    case at.REMOVE_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          data: state.items.data.filter((item) => item.itemId === action.payload.itemId),
        },
      }
    case at.FETCH_REQUEST:
      return {
        ...state,
        items: {
          ...state.items,
          isFetching: true,
          fetchError: null,
        },
      }
    case at.FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          hasSubscription: action.payload.hasSubscription,
          pendingSubscription: action.payload.pendingSubscription,
        },
        items: {
          ...state.items,
          isFetching: false,
          data: [
            ...state.items.data,
            ...action.payload.items.filter(
              (item) => !state.items.data.find((row) => row.itemId === item.itemId)
            ),
          ],
        },
      }
    case at.FETCH_FAILURE:
      return {
        ...state,
        items: {
          ...state.items,
          isFetching: false,
          fetchError: action.payload,
        },
      }
    case at.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          isFetching: true,
          fetchError: null,
        },
      }
    case at.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          isFetching: false,
          data: action.payload,
        },
      }
    case at.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          isFetching: false,
          fetchError: action.payload,
        },
      }
    case at.SUBSCRIBE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          isSubscribing: true,
        },
      }
    case at.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          isSubscribing: false,
          hasSubscription: true,
        },
      }
    case at.SUBSCRIBE_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          isSubscribing: false,
          hasSubscription: false,
        },
      }
    case at.REJECT_SUBSCRIPTION:
      return {
        ...state,
        data: {
          ...state.data,
          rejectedSubscription: true,
        },
      }
    case at.FETCH_GROUPED_ID_RELATIONS_SUCCESS:
      return {
        ...state,
        groupedIdRelations: action.payload,
      }
    default:
      return state
  }
}
