import * as React from 'react'
import useLazyImgSrc from 'hooks/useLazyImageSrc'

type Props = {
  src: string
  default: string
  alt: string
  as?: any
  offset?: number
  'data-cy-handle'?: string
}
export default function LazyImg(props: Props) {
  const [ref, src] = useLazyImgSrc(props.src, props.default, props.offset || 0)

  if (props.as) {
    return <props.as src={src} alt={props.alt} ref={ref} data-cy-handle={props['data-cy-handle']} />
  }
  return <img src={src} alt={props.alt} ref={ref} data-cy-handle={props['data-cy-handle']} />
}
