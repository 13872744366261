import './rules'
import store from 'store'
import reducer, { State } from './reducer'
import { Action } from './actions'

store.injectReducer(store, 'browser', reducer)

declare global {
  interface RootState {
    browser: State
  }
  interface RootReducers {
    browser: typeof reducer
  }
  interface ModuleActions {
    browser: Action
  }
}

export { default as useMediaSize } from './hooks/useMediaSize'

export default reducer
