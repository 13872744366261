// @flow
import * as React from 'react'
import { FiUser } from 'react-icons/fi'
import { useUser } from 'modules/user'
import config from 'config'
import Icon from '../Icon'

const featureEnabled = config.checkout.enabled
const { accountUrl } = config.checkout

type Props = {
  'data-cy-state': string
}

export default function UserIcon(props: Props) {
  const user = useUser()
  if (!featureEnabled) return null

  return (
    <Icon
      icon={FiUser}
      data-cy-state={props['data-cy-state']}
      data-cy-handle="user-icon"
      {...{
        ...(user.data.isLoggedIn ? { to: accountUrl } : { onClick: () => user.login() }),
      }}
    />
  )
}
