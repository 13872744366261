// @flow
import config from 'config'

export default function formatUrl(url: string) {
  if (!url || url[0] !== '/') return url
  const pathnameMatch = url.match(/[^#|^?]*/)
  const hashMatch = url.match(/#[^?]*/)
  const searchMatch = url.match(/\?[^#]*/)
  let pathname = pathnameMatch ? pathnameMatch[0] : ''
  let hash = hashMatch ? hashMatch[0] : ''
  let search = searchMatch ? searchMatch[0] : ''
  // add trailing slash
  if (pathname[pathname.length - 1] !== '/') pathname += '/'

  return `${config.prefix}${pathname.toLowerCase()}${search}${hash}`
}
