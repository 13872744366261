import * as React from 'react'
import Icon from '../Icon'
import { FiHeart } from 'react-icons/fi'
import { useWishlistCount } from 'modules/wishlist'

type Props = {
  'data-cy-state': string
}

export default function WishlistIcon(props: Props) {
  const { count } = useWishlistCount()
  return (
    <Icon
      icon={FiHeart}
      to="/wishlist/"
      numItems={count}
      data-cy-state={props['data-cy-state']}
      data-cy-state-hasNumItems="has-wishlist-items"
    />
  )
}
