import { Action } from './actions'
import * as at from './const'
import * as t from './types'
import calcMediaSize from './utils/calcMediaSize'

export type State = {
  mediaSize: t.MediaSize
  isServer: boolean
}

export const defaultState = {
  mediaSize: calcMediaSize(),
  isServer: typeof window === 'undefined',
}

export default function reducer(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case at.SET_MEDIA_SIZE:
      return { ...state, mediaSize: action.payload }
    default:
      return state
  }
}
