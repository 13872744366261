import React from 'react'
import Layout from './src/containers/GlobalLayout'

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
  if (props.pageContext?.skipGlobalLayout) {
    return <>{element}</>
  }
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
