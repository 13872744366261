import * as React from 'react'
import styled from 'styled-components'
import t from 'utils/typographie'
import { ms } from 'modules/browser/const'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import Link from 'theme/atoms/Link'

type Props = {
  label: string
  items: {
    rel: string
    relativeUrl: string
    shop: string
    label: string
  }[]
}

export default function LinkList(props: Props) {
  const [open, setOpen] = React.useState(false)
  return (
    <Wrapper className="LinkList" data-cy-collection="LinkList">
      <div className="label" onClick={() => setOpen(!open)} data-cy-handle="dropdown-btn">
        <span>{props.label}</span>
        <div className="chevron">
          {open ? <FaChevronUp data-cy-state="dropdown-status" /> : <FaChevronDown />}
        </div>
      </div>
      <Bullets visible={open}>
        <li className="label">{props.label}</li>
        {props.items.map((link) => {
          if (link.rel === 'nofollow') {
            return (
              <li key={link.label}>
                <Link to={link.relativeUrl} noFollow={true}>
                  {link.label}
                </Link>
              </li>
            )
          }
          return (
            <li key={link.label}>
              <Link to={link.relativeUrl}>{link.label}</Link>
            </li>
          )
        })}
      </Bullets>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  ${t([11, 2.4, 25], 'Raleway')}
  @media (min-width: ${ms.LAPTOP}px) {
    padding-left: 0;
    margin: 15px 0;
  }

  @media (min-width: ${ms.LAPTOP_XL}px) {
    margin: 25px 0;
  }

  > .label {
    flex: 1;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    text-transform: uppercase;
    @media (min-width: ${ms.LAPTOP}px) {
      display: none;
    }
  }

  > .chevron {
    @media (min-width: ${ms.LAPTOP}px) {
      display: none;
    }
  }
`

const Bullets = styled.ul`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  margin: 0;
  padding: 0;
  flex-basis: 100%;
  margin-top: 8px;

  > li {
    margin-right: 15px;
    list-style: none;
    > a {
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  > li.label {
    display: none;
    text-transform: uppercase;
  }

  @media (min-width: ${ms.LAPTOP}px) {
    display: flex;
    flex-wrap: wrap;
    flex-basis: unset;
    margin-top: 0;

    > li {
      display: block;
    }

    > li.label {
      display: block;
      font-weight: bold;
      margin-right: 20px !important;
    }
  }
`
