import * as t from '../types'
import * as s from '../selectors'
import useConnect, { Config } from 'hooks/useBetterConnect'
import { State } from '../reducer'

export type Input = {
  operator: '<' | '<=' | '>' | '>='
  mediaSize: t.MediaSize
  matchOnServer?: boolean
}

export type Output = {
  match: boolean
  activeMs: t.MediaSize
  isServer: boolean
}

const config: Config<Input, Output, State, never> = {
  moduleKey: 'browser',
  name: 'browser/useMediaSize',
  createCacheKey: (props) => props.operator + props.mediaSize + String(props.matchOnServer),
  mapState: (state, props) => {
    const result = {
      match: true,
      isServer: typeof window === 'undefined',
      activeMs: s.getMediaSize(state)
    }
    if (props.matchOnServer && result.isServer) {
      result.match = true
    } else if (props.operator === '<') {
      result.match = s.smallerThan(state, props.mediaSize)
    } else if (props.operator === '<=') {
      result.match = s.smallerThan(state, props.mediaSize) || s.is(state, props.mediaSize)
    } else if (props.operator === '>') {
      result.match = s.biggerThan(state, props.mediaSize)
    } else if (props.operator === '>=') {
      result.match = s.biggerThan(state, props.mediaSize) || s.is(state, props.mediaSize)
    }

    return result
  },
}

export default function useMediaSize(
  operator: '<' | '<=' | '>' | '>=',
  mediaSize: t.MediaSize,
  matchOnServer = true
): Output {
  const props: Input = { operator, mediaSize, matchOnServer }
  const hook: Output = useConnect(props, config)
  return hook
}
