// @flow
import { State } from './reducer'
import * as t from './types'
import { createSelector } from 'reselect'

export const getWishlistId = (state: State): string => state.wishlistId

export const getItemsCount = (state: State): number => state.items.data.length

export const getListingItem = (state: State, objectId: string): t.WishlistItem | null => {
  const dict = itemDictByProductId(state)
  return dict[objectId] || null
}

export const getGroupedItem = (state: State, groupedId: string): t.WishlistItem | null => {
  const dict = itemDictByGroupedId(state)
  return dict[groupedId] || null
}

export const isAddingGroupedItem = (state: State, sku: string): boolean => {
  return state.items.pending.includes(sku)
}

export const isAddingListingItem = (state: State, objectId: string): boolean => {
  return state.items.pending.includes(objectId)
}

export const getGroupedId = (state: State, sku: string): string | null =>
  state.groupedIdRelations[sku] || null

export const getAllItems = (state: State): t.WishlistItem[] => state.items.data

export const isFetchingItems = (state: State): boolean => state.items.isFetching

export const isFetchingProducts = (state: State): boolean => state.products.isFetching

export const getProductsFetchError = (state: State): null | string => state.products.fetchError

export const getProducts = (state: State): t.WishlistProduct[] => state.products.data

export const getWishlist = (state: State): t.Wishlist => state.data

// dictionaries

const itemDictByProductId: (
  state: State
) => { [objectId: string]: t.WishlistItem } = createSelector(
  (state: State) => state.items.data,
  (items: t.WishlistItem[]) => {
    let dict = {}
    for (let item of items) dict[item.productId] = item
    return dict
  }
)

const itemDictByGroupedId: (
  state: State
) => { [groupedId: string]: t.WishlistItem } = createSelector(
  (state: State) => state.items.data,
  (items: t.WishlistItem[]) => {
    let dict = {}
    for (let item of items) {
      if (item.groupedId) {
        dict[item.groupedId] = item
      }
    }
    return dict
  }
)
