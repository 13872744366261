import { applyMiddleware, compose, createStore } from 'redux'
import createRootReducer, { injectReducer } from './rootReducer'
import ruleMiddleware from 'redux-ruleset'

let composeWithDevtools = compose

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

if (typeof window !== 'undefined') {
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    composeWithDevtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }
}

declare global {
  interface RootState {}
  interface RootStore {
    subscribe: (arg0: () => void) => () => void
    asyncReducers: any
    injectReducer: typeof injectReducer
    getState: () => RootState
    dispatch: (action: RootAction) => RootAction
    replaceReducer: any
  }
}

const store: any = createStore(
  // @ts-ignore
  createRootReducer(),
  undefined,
  composeWithDevtools(applyMiddleware(ruleMiddleware))
)
store.asyncReducers = {}
store.injectReducer = injectReducer

export default store as RootStore
