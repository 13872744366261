import * as React from 'react'
import styled, { css } from 'styled-components'
import { FiSearch } from 'react-icons/fi'
import useFocus from 'hooks/useFocus'
import config from 'config'
import * as actions from '../actions'
import { navigate } from 'theme/atoms/Link'

type Props = {
  showSearch: boolean
  setShowSearch: (b: boolean) => void
}

export default function SearchForm(props: Props) {
  const input = React.useRef<null | HTMLInputElement>(null)
  const [focus, open, ref] = useFocus()
  const [search, setSearch] = React.useState('')

  /** focus input when clicked on search icon */
  React.useEffect(() => {
    props.setShowSearch(open)
    if (input.current && open) input.current.focus()
  }, [open])

  const triggerSearch = (search: string) => {
    actions.triggerSearch(search)
    if (search === '') {
      navigate('/shop/bekleidung/')
      return
    }
    const categoryId = Object.keys(config.categories).find((id) => {
      const cat = config.categories[id]
      if (cat.urlKey.includes('fuer-den-mann')) return false
      const catList = cat.categoryPath.split(' > ')
      const categoryPath = catList[catList.length - 1] || ''
      return categoryPath.toLowerCase() === search.toLowerCase()
    })
    if (categoryId) navigate(config.categories[categoryId].urlKey + '/')
    else navigate('/search/#q=' + encodeURIComponent(search))
    search && setSearch('')
  }

  const handleSearchIconClick = () => {
    if (window.innerWidth < 800 && !open) focus()
    else triggerSearch(search)
  }

  const handleSubmit = (e: Event) => {
    e.preventDefault()
    triggerSearch(search)
  }

  return (
    <Wrapper
      ref={ref}
      showSearch={props.showSearch}
      tabIndex="1"
      className="SearchForm"
      onSubmit={handleSubmit}
      data-cy-collection="SearchForm"
    >
      <input
        type="text"
        ref={input}
        value={search}
        data-cy-handle="search-field"
        onChange={(e) => setSearch(e.target.value)}
        onFocus={focus}
        data-cy-state={open && 'expanded'}
      />
      <FiSearch data-cy-handle="search-icon" onClick={handleSearchIconClick} />
    </Wrapper>
  )
}

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  height: 30px;
  &:focus {
    outline: none;
  }
  position: relative;

  > input {
    width: 0px;
    max-width: 0px;
    background: none;
    font-size: 14px;
    line-height: 14px;
    height: 20px;
    border: none;
    color: white;
    transition: width 300ms ease-out, max-width 300ms ease-out;
    margin-bottom: -5px;
    &:focus {
      outline: none;
    }
  }

  > svg {
    color: white;
    font-size: 28px;
    cursor: pointer;
  }

  ${(p) =>
    p.showSearch &&
    css`
      border-bottom: 1px solid white;
      margin-bottom: 2px;
      padding-bottom: 2px;

      > svg {
        color: white;
        font-size: 25px;
      }

      > input {
        width: calc(100vw - 160px);
        max-width: 220px;
      }
    `}

  @media (min-width: 800px) {
    border-bottom: 1px solid white;
    margin-bottom: 2px;
    padding-bottom: 2px;

    > svg {
      color: white;
      font-size: 25px;
    }

    > input {
      transition: none;
      width: 220px;
      max-width: 220px;
    }
  }
`
