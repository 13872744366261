export const ms = Object.freeze({
  MOBILE_M: 0,
  MOBILE_L: 375,
  TABLET: 525,
  LAPTOP: 768,
  LAPTOP_L: 990,
  LAPTOP_XL: 1200,

  // Container
  CONTAINER: 1160,
  CONTAINER_SMALL: 855,
})

export const orderedMediaSizes = [
  'MOBILE_M',
  'MOBILE_L',
  'TABLET',
  'LAPTOP',
  'LAPTOP_L',
  'LAPTOP_XL',
]

export const SET_MEDIA_SIZE: 'browser/SET_MEDIA_SIZE' = 'browser/SET_MEDIA_SIZE'
