import { Action } from './actions'
import * as at from './const'
import * as t from './types'

export type State = {
  cartOpen: boolean
  productModalId: string | null
  squarelovinId: string | null
  sidebarOpen: boolean
  sidebarComponent: t.SidebarComponent
}

export const defaultState = {
  cartOpen: false,
  productModalId: null,
  sidebarOpen: false,
  sidebarComponent: null,
  squarelovinId: null,
}

export default function reducer(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case at.CLEAR:
      return defaultState
    case at.SET_SQUARELOVIN_ID:
      return { ...state, squarelovinId: action.payload }
    case at.OPEN_PRODUCT_MODAL:
      return { ...state, productModalId: action.payload }
    case at.CLOSE_PRODUCT_MODAL:
      return { ...state, productModalId: null }
    case at.SET_SIDEBAR_COMPONENT:
      return { ...state, sidebarComponent: action.payload }
    case at.SET_CART_OPEN:
      return { ...state, cartOpen: action.payload }
    case at.SET_SIDEBAR_OPEN:
      return { ...state, sidebarOpen: action.payload }
    default:
      return state
  }
}
