import { State } from './reducer'
import * as t from './types'
import { orderedMediaSizes } from './const'

export const is = (state: State, ms: t.MediaSize): boolean => state.mediaSize === ms

export const biggerThan = (state: State, ms: t.MediaSize): boolean => {
  for (let size of orderedMediaSizes) {
    if (size === state.mediaSize) return false
    if (size === ms) return true
  }
  return false
}

export const smallerThan = (state: State, ms: t.MediaSize): boolean => {
  for (let i = orderedMediaSizes.length - 1; i >= 0; i--) {
    let size = orderedMediaSizes[i]
    if (size === state.mediaSize) return false
    if (size === ms) return true
  }
  return false
}

export const getMediaSize = (state: State) => state.mediaSize
