/**
 * Defines font-family, font-weight, font-size, letter-spacing and line-height
 * @param fontSize [ font-size, letter-spacing, line-height ]
 * @param fontFamily 'Raleway' | 'Cormorant Garamond'
 * @param fontWeight 'bold' | '500' | 'normal'
 * @param fontStyle 'italic'
 */
export default function create(
  fontSize: [number, number, number],
  fontFamily?: 'Raleway' | 'Cormorant Garamond' | 'Open Sans',
  fontWeight?: 'bold' | '500' | 'normal',
  fontStyle?: 'italic'
) {
  const [fontS, letterS, lineH] = fontSize
  let result =
    `font-size: ${fontS}px;` + `letter-spacing: ${letterS}px;` + `line-height: ${lineH}px;`

  if (fontFamily) result += `font-family: ${fontFamily};`
  if (fontWeight) result += `font-weight: ${fontWeight};`
  if (fontStyle) result += `font-style: ${fontStyle};`
  return result
}

/**
 *
 * text: create([16,5,24], 'Cormorant Garamond', '500')
 */
create.named = function(name: 'text' | 'text-small') {
  return {
    text: create([16, 5, 24], 'Cormorant Garamond', '500'),
  }[name]
}
