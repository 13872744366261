import React from 'react'
import { navigate } from 'theme/atoms/Link'
import {dispatchEvent} from 'redux-ruleset'
import {sendEvent} from 'modules/tracking/actions'

export default class ErrorBoundary extends React.Component {
  // constructor(props) {
  //   super(props)
  //   // this.state = { hasError: false }
  // }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true }
  // }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log(error)
    dispatchEvent(sendEvent({
      event: 'triggeredGenericEvent',
      eventAction: error.toString(),
      eventCategory: 'Application Error',
      eventLabel: window.location.pathname,
      eventRequiresCustomisation: true,
    }))
    // console.log({
    //   event: 'triggeredGenericEvent',
    //   eventAction: error.toString(),
    //   eventCategory: 'Application Error',
    //   eventLabel: window.location.pathname,
    //   eventRequiresCustomisation: true,
    // })
    navigate('/500/')
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return null
    // }

    return this.props.children // eslint-disable-line react/prop-types
  }
}
