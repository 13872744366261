import { dispatchEvent } from 'redux-ruleset'

export const CLICK_ITEM: 'Sidebar/CLICK_ITEM' = 'Sidebar/CLICK_ITEM'

export const clickItem = (label: string) =>
  dispatchEvent({
    type: CLICK_ITEM,
    payload: label,
  })

export type ClickItem = ReturnType<typeof clickItem>

declare global {
  interface RulesetDispatchEvents {
    'core/Sidebar': ClickItem
  }
}
