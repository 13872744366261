import calcMediaSize from './utils/calcMediaSize'
import * as actions from './actions'
import * as s from './selectors'
import store from 'store'

if (typeof window !== 'undefined') {
  window.addEventListener('resize', () => {
    const state = store.getState()
    const newMediaSize = calcMediaSize()
    const mediaSize = s.getMediaSize(state.browser)
    if (mediaSize !== newMediaSize) {
      store.dispatch(actions.setMediaSize(newMediaSize))
    }
  })
}
