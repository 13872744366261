import * as React from 'react'
import styled from 'styled-components'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import Link from 'theme/atoms/Link'

type Item = {
  label: string
  link: string
  children?: Item[]
}

type Props = {
  title: string
  onBackClick: () => void
  onItemClick: (e: Event, item: Item) => void
  items: Item[]
}

export default function LinkList(props: Props) {
  const handleItemClick = (item) => (e) => {
    props.onItemClick(e, item)
  }
  return (
    <Wrapper className="LinkList" data-cy-collection="LinkList">
      <div className="back">
        <FiChevronLeft />
        <button onClick={props.onBackClick} data-cy-handle="back-btn">
          ZURÜCK
        </button>
      </div>

      <div className="title">{props.title}</div>

      <div className="items">
        {props.items.map((item) => (
          <Link
            key={item.label}
            to={item.link || '#'}
            onClick={handleItemClick(item)}
            data-cy-handle="list-item-btn"
          >
            <div className="label">{item.label}</div>
            {item.children && (
              <div className="icon" data-cy-state="has-children">
                <FiChevronRight />
              </div>
            )}
          </Link>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 16px;
  padding-bottom: 80px;

  > .back {
    padding: 15px;
    padding-left: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    > svg {
      margin-right: 5px;
      color: #993351;
    }
    > button {
      cursor: pointer;
      font-size: 11px;
      font-weight: bold;
      color: #993452;
      border: none;
      background: none;
      padding: 0;
      font-family: Raleway;
      letter-spacing: 2.4px;
      font-weight: 700;
    }
  }

  > .title {
    background: white;
    margin-bottom: 15px;
    color: rgb(85, 85, 85);
    text-transform: uppercase;
    padding: 10px;
    color: #555555;
    font-family: Raleway;
    font-size: 15px;
    letter-spacing: 1.7px;
    text-align: center;
  }

  > .items {
    > a,
    .a-like {
      display: flex;
      padding: 8.5px 5px;
      border-bottom: 2px solid white;
      text-decoration: none;
      cursor: pointer;
      color: rgb(85, 85, 85);
      align-items: center;
      font-family: Raleway;
      font-size: 11px;
      letter-spacing: 1.2px;
      line-height: 20px;
      &:hover {
        background: whitesmoke;
        color: #993350;
      }
      > .label {
        flex: 1;
      }
      > .icon {
        line-height: 1;
      }
    }
  }
`
